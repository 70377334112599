import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    // // If the authentication status is loading, do nothing yet
    // if (isLoading) return;

    // If the user is authenticated, navigate to the dashboard
    if (isLoading) {
      console.log("Authenticated");
      // navigate("/");
    } else if (!isAuthenticated && !isLoading) {
      console.log("not auth");
      // If the user is not authenticated, trigger the login redirect
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, navigate]);

  return null; // Don't render anything
};
export default Login;
