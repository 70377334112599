import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "./HomeTabs/style.css";
import { Grid, Avatar, Container } from "@mui/material";
import HomeIcon from "../HomeIcon/HomeIcon";
import InfectionSafeIcon from "../InfectionSafeIcon/InfectionSafeIcon";
import ReportsIcon from "../ReportsIcon/ReportsIcon";
import { KeyboardArrowDown as DownIcon } from "@mui/icons-material";
import { useCookies } from "react-cookie";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import {
  Tabs,
  Tab,
  Menu,
  MenuItem,
  Toolbar,
  AppBar,
  CssBaseline,
  Box,
  Button,
} from "@mui/material";

import HeaderMagnifyingGlassIcon from "../HeaderMagnifyingGlassIcon/HeaderMagnifyingGlassIcon";
import HeaderBellIcon from "../HeaderBellIcon/HeaderBellIcon";
import HeaderQuestionMarkIcon from "../HeaderQuestionMarkIcon/HeaderQuestionMarkIcon";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import ShowOutstandingNotifications from "../MainContainer/HomeTabs/ShowOutstandingNotifications/ShowOutstandingNotifications";
import HomeTemplate from "../HomeTemplate/HomeTemplate";
import InfectionControlConsequences from "../MainContainer/HomeTabs/InfectionControlConsequences";
import InfectionControlSummary from "../MainContainer/HomeTabs/InfectionControlSummary";
import InfectionControlSummaryMap from "../MainContainer/HomeTabs/InfectionControlSummaryMap";
import ManageCase from "./HomeTabs/ManageCase";
import PersonEditor from "./UserManagement/PersonEditor";
import Reports from "../MainContainer/HomeTabs/Reports/Reports";
import MyProfile from "../MainContainer/MyProfile/MyProfile";
import PersonManagement from "./UserManagement/PersonManagement";
import ApplicationSettings from "../MainContainer/ApplicationSettings/ApplicationSettings";
import ReportUnderConstruction from "../MainContainer/HomeTabs/Reports/ReportUnderConstruction";

function MainContainer({
  sites,
  tags,
  refreshTags,
  signOut,
  currentLocation,
  setCurrentLocation,
}) {
  const [cookies, setCookie] = useCookies(["defaultSite"]);
  const navigate = useNavigate();
  const location = useLocation();
  const { user: auth0User } = useAuth0();
  const [logoUrl, setLogoUrl] = useState(null);
  const [middleNavbarImage, setMiddleNavbarImage] = useState(null);

  /* Location menu */

  const [locationMenuAnchorEl, setLocationMenuAnchorEl] = React.useState(null);
  const locationMenuOpen = Boolean(locationMenuAnchorEl);

  const [currentTab, setCurrentTab] = useState(
    location && location.pathname ? location.pathname : "/"
  );

  const handleLocationMenuClick = (event) => {
    setLocationMenuAnchorEl(event.currentTarget);
  };

  const handleLocationMenuClose = () => {
    setLocationMenuAnchorEl(null);
  };

  /* User menu */

  const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState(null);
  const userMenuOpen = Boolean(userMenuAnchorEl);

  const handleUserMenuClick = (event) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchorEl(null);
  };

  /* Infection safe menu */

  const [infectionSafeMenuAnchorEl, setInfectionSafeMenuAnchorEl] =
    React.useState(null);
  const infectionSafeMenuOpen = Boolean(infectionSafeMenuAnchorEl);
  const handleInfectionSafeMenuClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    handleInfectionSafeMenuClose();

    setInfectionSafeMenuAnchorEl(event.currentTarget);
  };

  const handleInfectionSafeMenuClose = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setInfectionSafeMenuAnchorEl(null);
  };

  const infectionSafeMenuItems = [
    { label: "Infection Control Summary", url: "/" },
    { label: "Add New Infection Case", url: "/infection-safe/manage-case" },
  ];

  const userMenuItems = [
    { label: "My Profile", url: "/my-profile" },
    { label: "Person Management", url: "/people", admin: true },
    { label: "Application Settings", url: "/application/settings" },
  ];

  const theme = createTheme({
    palette: {
      primary: {
        main: "#01c1d3",
        contrastText: "#fff",
      },
      secondary: {
        main: "#283555",
        contrastText: "#fff",
      },
    },
    components: {
      MuiToolbar: {
        styleOverrides: {
          dense: {
            height: 46,
            minHeight: 46,
          },
        },
      },
    },
    overrides: {
      MuiButton: {
        containedInfo: {
          color: "#4D6879",
        },
      },
    },
  });

  const appBarStyles = {
    background: "#fff",
    color: "#283555",
    boxShadow: "0px 1px 10px 0px rgb(0 0 0 / 15%)",
    borderBottom: "1px solid #e3e3e3",
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center",
    justifyContent: "center",
  };

  const careHomeLogoStyle = {
    height: "37px",
    width: "154px",
  };

  const NHSLogoStyle = {
    height: "auto",
    width: "121px",
  };

  const centerNavbarImageStyle = {
    height: "41px",
    width: "148px",
  };

  const upperRightUserMenuStyle = {
    display: "inline-flex",
    alignItems: "center",
    color: "#283555",
  };

  const downCaretStyle = {
    color: "#919191",
  };

  const lowerNavDownCaretStyle = {
    marginRight: "-11px",
    marginLeft: "11px",
  };

  const currentLocationStyle = {
    margin: 5,
    color: "#283555",
    display: "inline-flex",
    alignItems: "center",
  };

  const currentLocationTextStyle = {
    marginLeft: 2,
  };

  const navbarUserInfo = {
    margin: 8,
    marginLeft: 12,
    marginRight: 20,
    lineHeight: "1.3",
  };

  const navbarUserInfoFirstLine = {
    fontWeight: 600,
    fontSize: 14,
  };

  const navbarUserInfoSecondLine = {
    fontWeight: 400,
  };

  const upperNavbarIconStyle = {
    borderRadius: 30,
    color: "#EAF0FF",
    marginRight: "5px",
  };

  const upperAvatarContainerStyle = {
    background: "#EAF0FF",
    borderRadius: 30,
    width: 40,
    height: 40,
    margin: 5,
  };

  const toolbarStyle = {
    height: 58,
    borderTopWidth: 1,
    borderTopColor: "#F2F2F2",
    borderRightStyle: "solid",
    borderRightWidth: 1,
    borderRightColor: "#F2F2F2",
    borderRightStyle: "solid",
    borderBottomWidth: 1,
    borderBottomColor: "#F2F2F2",
    borderBottomStyle: "solid",
  };

  const lastLowerTabStyle = {
    borderRightWidth: 0,
    borderRightColor: "#fff",
  };

  const lowerTabStyles = {
    overflow: "visible",
    position: "relative",
    display: "inline-flex",
    fontWeight: 600,
    height: 58,
    color: "#283555",
    paddingTop: 20,
    fontSize: "16px",
    textTransform: "none",
    fontSize: 15,
    letterSpacing: "-0.45px",
    borderRightWidth: 1,
    borderRightColor: "#F2F2F2",
    borderRightStyle: "solid",
    paddingRight: 24,
    paddingLeft: 24,
    minWidth: 0,
    minHeight: 0,
    paddingTop: 0,
    paddingBottom: 0,
  };

  const tabContainerStyle = {
    display: "inline-block",
    height: 58,
  };

  const tabIconStyle = {
    fontSize: 13,
    color: "#283555",
    width: 28,
    height: 28,
    background: "#EAF0FF",
    borderRadius: 30,
    marginRight: "13px",
  };

  const tabBoxStyle = {
    borderBottom: "none",
    borderColor: "divider",
    width: "100%",
    padding: "0px 0px 0px 20px",
    display: "flex",
    height: 58,
    justifyContent: "center",
    alignItems: "center",
  };

  const handleTabChange = (evt, tab) => {
    setCurrentTab(tab);

    navigate(tab);

    if (tab !== "/infection-safe") handleInfectionSafeMenuClose();
  };

  const upperToolbarStyle = {
    background: "#FFF",
    display: "inline-flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
    borderRightWidth: 1,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderColor: "#F2F2F2",
    borderStyle: "solid",
    color: "#283555",
    fontSize: 12,
  };

  const onAvatarError = (e) => {
    e.target.src = `${process.env.REACT_APP_S3_URL}/avatar.png`;
  };

  React.useEffect(() => {
    // TODO: Get client type from the API
    setLogoUrl(
      auth0User.isCareHome
        ? `${process.env.REACT_APP_S3_URL}/proxximos_safer_care.png`
        : `${process.env.REACT_APP_S3_URL}/nhs_logo.png`
    );
    // TODO: Get the logo from the API
    setMiddleNavbarImage(
      `${process.env.REACT_APP_S3_URL}/client_logos/${auth0User.clientId}/logo.png` // Add cachebuster to force refresh
    );
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CssBaseline />
          <AppBar
            position="relative"
            sx={{
              display: "flex",
              background: "#1C54C1",
              color: "#fff",
              boxShadow: "none",
              zIndex: "1200",
            }}
          >
            <Toolbar style={upperToolbarStyle}>
              <div class="logocontainer">
                {logoUrl ? (
                  <a href="/" style={{ border: 0, outline: 0 }}>
                    <img
                      src={logoUrl}
                      style={
                        auth0User.isCareHome ? careHomeLogoStyle : NHSLogoStyle
                      }
                      alt="Proxximos"
                    />
                  </a>
                ) : (
                  <></>
                )}
              </div>

              <div class="center-navbar-image">
                {/* {middleNavbarImage ? (
                  <img
                    src={middleNavbarImage}
                    style={centerNavbarImageStyle}
                    alt="Logo"
                  />
                ) : (
                  <></>
                )} */}
                {sites && currentLocation ? (
                  <div style={{ position: "relative" }}>
                    <Button
                      aria-controls={
                        locationMenuOpen ? "basic-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={locationMenuOpen ? "true" : undefined}
                      onClick={handleLocationMenuClick}
                    >
                      <div
                        id="location-menu-label"
                        style={currentLocationStyle}
                      >
                        <DownIcon style={downCaretStyle} />
                        <span style={currentLocationTextStyle}>
                          {currentLocation.name}
                        </span>
                      </div>
                    </Button>

                    <Menu
                      id="location-menu"
                      anchorEl={locationMenuAnchorEl}
                      open={locationMenuOpen}
                      // open={true}
                      onClose={handleLocationMenuClose}
                      onBlur={handleLocationMenuClose}
                      hideBackdrop
                      disableEnforceFocus
                      disablebackdropclick
                      disableScrollLock
                      disableAutoFocusItem
                      disableRestoreFocus
                      disablePortal
                      style={{
                        position: "absolute",
                        top: "58px",
                        left: 0,
                      }}
                      MenuListProps={{
                        "aria-labelledby": "location-menu-label",
                      }}
                      sx={{
                        "& .MuiMenu-list": {
                          paddingTop: 0,
                          paddingBottom: 0,
                        },
                        "& .MuiMenu-paper": {
                          top: "0px !important",
                          left: "0px !important",
                          maxHeight: "none",
                          maxWidth: "none",
                          display: "inline-flex",
                          position: "relative",
                          marginLeft: "0px !important",
                          marginTop: "-17px !important",
                        },
                      }}
                      className="location-menu nav-menu"
                    >
                      {sites.map((item) => {
                        return (
                          <MenuItem
                            key={item.id}
                            onClick={(event) => {
                              event.stopPropagation();
                              setCurrentLocation(item);

                              setCookie("defaultSite", item);
                              handleLocationMenuClose();
                            }}
                            value={item.id}
                            className="location-menu-item nav-menu-item"
                          >
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div class="navbar-right">
                <HeaderQuestionMarkIcon style={upperNavbarIconStyle} />
                <HeaderMagnifyingGlassIcon style={upperNavbarIconStyle} />
                <HeaderBellIcon style={upperNavbarIconStyle} />

                <Button
                  aria-controls={userMenuOpen ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={userMenuOpen ? "true" : undefined}
                  onClick={handleUserMenuClick}
                >
                  <div style={upperRightUserMenuStyle}>
                    <DownIcon style={downCaretStyle} />
                    <div style={upperAvatarContainerStyle}>
                      <Avatar
                        alt="My Profile"
                        src={auth0User.picture}
                        imgProps={{ onError: onAvatarError }}
                      />
                    </div>
                    <div style={navbarUserInfo}>
                      <div id="user-name" style={navbarUserInfoFirstLine}>
                        {auth0User.name || "Test Name Here"}
                      </div>
                      <div style={navbarUserInfoSecondLine}>
                        {auth0User.email || "Placeholder Title"}
                      </div>
                    </div>
                  </div>
                </Button>
                <Menu
                  id="user-menu"
                  anchorEl={userMenuAnchorEl}
                  open={userMenuOpen}
                  onClose={handleUserMenuClose}
                  onBlur={handleUserMenuClose}
                  hideBackdrop
                  disableEnforceFocus
                  disablebackdropclick
                  disableScrollLock
                  disableAutoFocusItem
                  disableRestoreFocus
                  disablePortal
                  style={{
                    position: "absolute",
                    top: "58px",
                    left: 0,
                  }}
                  MenuListProps={{
                    "aria-labelledby": "user-menu-label",
                  }}
                  sx={{
                    "& .MuiMenu-list": {
                      paddingTop: 0,
                      paddingBottom: 0,
                    },
                    "& .MuiMenu-paper": {
                      top: "8px !important",
                      maxHeight: "none",
                      maxWidth: "none",
                      display: "inline-flex",
                      position: "relative",
                      marginLeft: "10px !important",
                    },
                  }}
                  className="user-menu nav-menu"
                >
                  {userMenuItems.map((item) => {
                    return (
                      // ((item["admin"] && isAdmin) || !item["admin"]) && (
                      <MenuItem
                        onClick={(event) => {
                          event.stopPropagation();
                          navigate(item.url);
                          handleUserMenuClose();
                        }}
                        className="user-menu-item nav-menu-item"
                      >
                        {item.label}
                      </MenuItem>
                      // )
                    );
                  })}
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();

                      signOut();
                    }}
                    className="user-menu-item nav-menu-item"
                  >
                    Sign out
                  </MenuItem>
                </Menu>
              </div>
            </Toolbar>
          </AppBar>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AppBar position="relative" sx={appBarStyles}>
                <Toolbar sx={toolbarStyle} variant="dense">
                  <Box sx={tabBoxStyle}>
                    <Tabs
                      style={{ ...tabContainerStyle, overflow: "visible" }}
                      value={currentTab}
                      onChange={handleTabChange}
                      aria-label="Navbar"
                      indicatorColor="primary"
                      textColor="primary"
                    >
                      <Tab
                        value="/infection-safe"
                        label={
                          <>
                            <span id="infection-safe-label">
                              Infection Safe
                            </span>
                            <DownIcon
                              style={{
                                ...downCaretStyle,
                                ...lowerNavDownCaretStyle,
                              }}
                            />{" "}
                            <Button
                              aria-controls={
                                infectionSafeMenuOpen ? "basic-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={
                                infectionSafeMenuOpen ? "true" : undefined
                              }
                              onClick={handleInfectionSafeMenuClick}
                              style={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 2,
                              }}
                            ></Button>
                            <Menu
                              id="infection-safe-menu"
                              // anchorEl={infectionSafeMenuAnchorEl}
                              open={infectionSafeMenuOpen}
                              onClose={handleInfectionSafeMenuClose}
                              onBlur={handleInfectionSafeMenuClose}
                              hideBackdrop
                              disableEnforceFocus
                              disablebackdropclick
                              disableScrollLock
                              disableAutoFocusItem
                              disableRestoreFocus
                              disablePortal
                              style={{
                                position: "absolute",
                                top: "58px",
                                left: 0,
                              }}
                              MenuListProps={{
                                "aria-labelledby": "infection-safe-label",
                              }}
                              sx={{
                                "& .MuiMenu-list": {
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                },
                                "& .MuiMenu-paper": {
                                  top: "0px !important",
                                  left: "0px !important",
                                  maxHeight: "none",
                                  maxWidth: "none",
                                  display: "inline-flex",
                                  position: "relative",
                                },
                              }}
                              className="infection-safe-menu nav-menu"
                            >
                              {infectionSafeMenuItems.map((item) => {
                                return (
                                  <MenuItem
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      setCurrentTab("/infection-safe");
                                      navigate(item.url);
                                      handleInfectionSafeMenuClose();
                                    }}
                                    className="infection-safe-menu-item nav-menu-item"
                                  >
                                    {item.label}
                                  </MenuItem>
                                );
                              })}
                            </Menu>
                          </>
                        }
                        iconPosition="start"
                        icon={<InfectionSafeIcon style={tabIconStyle} />}
                        style={lowerTabStyles}
                      />

                      <Tab
                        value="/reports"
                        label="Reports"
                        iconPosition="start"
                        icon={<ReportsIcon style={tabIconStyle} />}
                        style={{ ...lowerTabStyles, ...lastLowerTabStyle }}
                      />
                    </Tabs>
                  </Box>
                </Toolbar>
              </AppBar>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </Box>
        <Container maxWidth="xl">
          <Routes>
            <Route path="/dashboard" element={<HomeTemplate />} />

            <Route
              path="/"
              element={
                <InfectionControlSummary pdfHeaderImage={middleNavbarImage} />
              }
            />
            <Route
              path="/infection-safe/summary/map"
              element={
                <InfectionControlSummaryMap
                  pdfHeaderImage={middleNavbarImage}
                />
              }
            />
            <Route
              path="/infection-safe/consequences"
              element={
                <InfectionControlConsequences
                  pdfHeaderImage={middleNavbarImage}
                  currentLocation={currentLocation}
                />
              }
            />

            <Route
              path="/infection-safe/manage-case"
              element={<ManageCase />}
            />

            <Route
              path="/people"
              element={<PersonManagement sites={sites} tags={tags} />}
            />
            <Route path="/my-profile" element={<MyProfile />} />
            <Route
              path="/application/settings"
              element={<ApplicationSettings />}
            />
            <Route
              path="/admin/manage-user"
              element={<PersonEditor tags={tags} refreshTags={refreshTags} />}
            />
            <Route
              path="/reports"
              element={<Reports pdfHeaderImage={middleNavbarImage} />}
            />
            <Route
              path="/reports/under-construction"
              element={<ReportUnderConstruction />}
            />
            <Route
              path="/infection-safe/show-outstanding-notifications"
              element={<ShowOutstandingNotifications />}
            />
          </Routes>
        </Container>
      </ThemeProvider>
    </>
  );
}

export default MainContainer;
