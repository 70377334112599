import React from "react";

function ZoomInIcon({ style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.667"
      height="26.667"
      viewBox="0 0 26.667 26.667"
    >
      <g
        id="Icon_feather-zoom-in"
        data-name="Icon feather-zoom-in"
        transform="translate(-3 -3)"
      >
        <path
          id="Path_41354"
          data-name="Path 41354"
          d="M24.985,14.743A10.243,10.243,0,1,1,14.743,4.5,10.243,10.243,0,0,1,24.985,14.743Z"
          fill="none"
          stroke={style.color ? style.color : "#283553"}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
        <path
          id="Path_41355"
          data-name="Path 41355"
          d="M30.544,30.544l-5.569-5.569"
          transform="translate(-2.999 -2.999)"
          fill="none"
          stroke={style.color ? style.color : "#283553"}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
        <path
          id="Path_41356"
          data-name="Path 41356"
          d="M16.5,12v7.682"
          transform="translate(-1.757 -1.098)"
          fill="none"
          stroke={style.color ? style.color : "#283553"}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
        <path
          id="Path_41357"
          data-name="Path 41357"
          d="M12,16.5h7.682"
          transform="translate(-1.098 -1.757)"
          fill="none"
          stroke={style.color ? style.color : "#283553"}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
      </g>
    </svg>
  );
}

export default ZoomInIcon;
