import React, { useState } from "react";
import { IconButton, Tooltip, Grid } from "@mui/material";
import PlayIcon from "../../../PlayIcon/PlayIcon";
import PauseIcon from "@mui/icons-material/Pause";
import FastForwardIcon from "../../../FastForwardIcon/FastForwardIcon";
import FastRewindIcon from "../../../FastRewindIcon/FastRewindIcon";
import Slider from "@mui/material/Slider";

import "../style.css";
import {
  fastForwardIconStyle,
  playIconStyle,
  fastRewindIconStyle,
  activeIconStyle,
} from "../../../styles/styles";
import { Line } from "react-chartjs-2";

function SimpleLineChart(props) {
  // Sample data for the chart
  const data = {
    labels: ["January", "February", "March", "April", "May"],
    datasets: [
      {
        data: [10, 20, 15, 25, 30],
        borderColor: "rgba(169, 169, 169, 1)", // Grey color
        borderWidth: 2,
        pointRadius: 0, // Hide data points
      },
    ],
  };

  // Customize chart options to hide axis and labels
  const options = {
    responsive: true, // Enable responsive sizing
    aspectRatio: 8, // Set the aspect ratio (width-to-height) to 2:1

    plugins: {
      legend: {
        display: false, // Hide legend
      },
    },
    scales: {
      x: {
        display: false, // Hide x-axis
      },
      y: {
        display: false, // Hide y-axis
      },
    },
  };

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <Line
        key={`line-chart-${parseInt(Math.random() * 100)}`}
        data={data}
        options={options}
        {...props}
      />
    </div>
  );
}

const timeStye = {
  color: "#777F9E",
  fontSize: "12px",
  marginBottom: "7px",
  fontWeight: 500,
  fontFace: "Open Sans",
  minWidth: 40,
};

const buttonWrapperStyle = {
  backgroundColor: "#C9D3EB",
  padding: "8px 8px",
};

const iconStyle = {
  fontSize: "24px",
  color: "#283555",
};

const customSliderStyle = {
  "& .MuiSlider-thumb": {
    color: "#00828E",
    marginTop: "6px",
  },
  "& .MuiSlider-track": {
    color: "#01C1D3",
    height: "13px",
    marginTop: "6px",
  },
  "& .MuiSlider-rail": {
    color: "#C9D3EB",
    height: "13px",
    marginTop: "6px",
  },
};

function VideoPlayer({
  showGraph = true,
  handlePlay,
  handlePause,
  handleForward5Seconds,
  handleRewind5Seconds,
  currentTime,
  duration,
  setCurrentTime,
  timeMarkers,
  fastForwardActive,
  rewindActive,
  playActive,
}) {
  const [isPlaying, setIsPlaying] = useState(false);

  const handleTimeChange = (event, newValue) => {
    setCurrentTime(newValue);
  };

  const handleFastForward = () => {
    handleForward5Seconds();
  };

  const handleFastRewind = () => {
    handleRewind5Seconds();
  };

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
    if (isPlaying) {
      handlePause();
    } else {
      handlePlay();
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  React.useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <div
      style={{
        marginLeft: "10px",
        marginRight: "22px",
        marginBottom: "34px",
        marginTop: "5px",
      }}
    >
      <Grid
        container
        spacing={2}
        className="controls-ui"
        style={{
          display: "inline-flex",
          alignItems: "flex-end",
          maxWidth: "100%",
          width: "100%",
        }}
      >
        <Grid item xs={4} sm={"auto"} style={{ textAlign: "right" }}>
          <Tooltip title={isPlaying ? "Pause" : "Play"}>
            <IconButton
              onClick={togglePlayPause}
              style={{ ...buttonWrapperStyle, width: "33px", height: "31px" }}
            >
              {isPlaying ? (
                <PauseIcon style={{ ...iconStyle }} />
              ) : (
                <PlayIcon
                  style={{
                    ...iconStyle,
                    ...playIconStyle,
                    ...(playActive && activeIconStyle),
                  }}
                />
              )}
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item xs={4} sm={"auto"} style={{ textAlign: "center" }}>
          <Tooltip title="5x slower">
            <IconButton onClick={handleFastRewind} style={buttonWrapperStyle}>
              <FastRewindIcon
                style={{
                  ...iconStyle,
                  ...fastRewindIconStyle,
                  ...(rewindActive && activeIconStyle),
                }}
              />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid
          item
          xs={4}
          sm={"auto"}
          display={{
            xs: "inline",
            sm: "none",
            md: "none",
            lg: "none",
            xl: "none",
          }}
          style={{ textAlign: "left" }}
        >
          <Tooltip title="5x faster">
            <IconButton onClick={handleFastForward} style={buttonWrapperStyle}>
              <FastForwardIcon
                style={{
                  ...iconStyle,
                  ...fastForwardIconStyle,
                  ...(fastForwardActive && activeIconStyle),
                }}
              />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item xs={12} sm={"auto"} style={{ flex: 1 }}>
          <div style={{ width: "100%", display: "inline-flex" }}>
            <div style={{ flex: 1 }}>
              <Slider
                value={currentTime}
                onChange={handleTimeChange}
                min={0}
                max={duration}
                step={null}
                defaultValue={timeMarkers[0] ? timeMarkers[0].value : null}
                valueLabelDisplay="auto"
                marks={timeMarkers}
                sx={customSliderStyle}
              />
            </div>
          </div>
        </Grid>

        <Grid
          item
          sm={"auto"}
          display={{
            xs: "none",
            sm: "inline-flex",
            md: "inline-flex",
            lg: "inline-flex",
            xl: "inline-flex",
          }}
        >
          <Tooltip title="5x faster">
            <IconButton onClick={handleFastForward} style={buttonWrapperStyle}>
              <FastForwardIcon
                style={{
                  ...iconStyle,
                  ...fastForwardIconStyle,
                  ...(fastForwardActive && activeIconStyle),
                }}
              />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </div>
  );
}

export default VideoPlayer;
