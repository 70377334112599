import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useNavigate, useLocation } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import CircleCheckedFilled from "@mui/icons-material/CheckCircle";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "../HomeTabs/style.css";
import {
  titleStyle,
  inputLabelStyle,
  inputStyle,
  containerStyle,
} from "../../styles/styles";
import GoBack from "../../../components/GoBack/GoBack";

const label = { inputProps: { "aria-label": "Action taken" } };

const radioLabelStyle = {
  fontSize: "14px",
  fontWeight: 600,
  color: "#414254",
};

export default function MyProfile({ user }) {
  let adminFlag = false;
  try {
    adminFlag = user.isAccountAdmin;
  } catch (e) {
    console.log(e);
  }
  const { state } = useLocation();
  const [snackbarOpen, setSnackbarOpen] = React.useState(
    state && state.snackbarSuccess ? true : false
  );
  const [myProfileWearableId, setMyProfileWearableId] = React.useState(
    parseInt(user.zoneinfo)
  );
  const [checkboxStates, setCheckboxStates] = React.useState(false);
  const [myProfileEmail, setMyProfileEmail] = React.useState(user.email);
  const [myProfileName, setMyProfileName] = React.useState(user.fullName);
  const [myProfileDefaultWard, setMyProfileDefaultWard] = React.useState(
    user.locale
  );
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [wearables, setWearables] = React.useState([
    {
      id: 11,
    },
    {
      id: 12,
    },
    {
      id: 13,
    },
    { id: 14 },
  ]);

  const [wards, setWards] = React.useState([
    {
      id: 1,
      name: "Ward 1",
    },
    {
      id: 2,
      name: "Ward 2",
    },
    {
      id: 3,
      name: "Ward 3",
    },
    { id: 4, name: "Ward 4" },
  ]);

  const alertTypes = [
    "Wander Alert",
    "New Case",
    "New Recommendation",
    "New Action",
  ];

  const contactMethodTypes = ["Email", "SMS", "Web app notification"];

  const handleCheckboxStateChange = (evt, alertType, contactMethod) => {
    const newCheckboxStates = { ...checkboxStates };

    if (newCheckboxStates[alertType]) {
      newCheckboxStates[alertType][contactMethod] = evt.target.checked;
    } else {
      contactMethodTypes.forEach((contactMethodType) => {
        newCheckboxStates[alertType][contactMethodType] = true;
      });
    }

    setCheckboxStates(newCheckboxStates);
  };

  const handleSave = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const initCheckboxStates = () => {
    const newCheckboxStates = { ...checkboxStates };

    alertTypes.forEach((alertType) => {
      newCheckboxStates[alertType] = {};

      contactMethodTypes.forEach((contactMethodType) => {
        newCheckboxStates[alertType][contactMethodType] = true;
      });
    });

    setCheckboxStates(newCheckboxStates);
  };

  React.useEffect(() => {
    if (!checkboxStates) {
      initCheckboxStates();
    }
  }, [checkboxStates]);

  return (
    <div>
      <Box style={containerStyle}>
        <GoBack />

        <br></br>
        <Typography variant="h5" component="div" sx={titleStyle}>
          My Profile
        </Typography>

        <Grid container style={{ marginTop: "30px" }}>
          <Grid item xs={3.8}>
            <label for="name" style={inputLabelStyle}>
              Full name
            </label>
            <br />
            <input
              type="text"
              id="name"
              name="name"
              value={myProfileName}
              style={inputStyle}
            />
          </Grid>
          <Grid item xs={3.8}>
            <label for="status" style={inputLabelStyle}>
              Wearable ID
            </label>
            <br />
            <select
              id="wearable-id"
              value={myProfileWearableId}
              name="wearable-id"
              style={inputStyle}
            >
              {wearables.map((wearable) => (
                <option value={wearable.id}>{wearable.id}</option>
              ))}
            </select>
          </Grid>
          <Grid item xs={3.8}>
            <label for="avatarupload" style={inputLabelStyle}>
              Upload/change profile picture
            </label>
            <input
              id="avatarupload"
              type="file"
              onChange={() => {}}
              accept="image/*"
              style={{
                borderRadius: "8px",
                fontSize: "13px",
                padding: "13px 44px 18px 44px",
                borderWidth: "0px",
                display: "block",
                margin: "auto",
                cursor: "pointer",
              }}
            ></input>
          </Grid>
        </Grid>

        <div
          className="horizontal-line"
          style={{ margin: "28px 0px 28px 0px" }}
        ></div>

        <Grid container>
          <Grid item xs={3.8}>
            <label for="default-ward" style={inputLabelStyle}>
              Default location
            </label>
            <br />
            <select
              id="default-ward"
              name="default-ward"
              value={myProfileDefaultWard}
              style={inputStyle}
            >
              {wards.map((ward) => (
                <option value={ward.name}>{ward.name}</option>
              ))}
            </select>
          </Grid>
          <Grid item xs={3.8}>
            <label for="email" style={inputLabelStyle}>
              Email
            </label>
            <br />
            <input
              type="text"
              id="email"
              name="email"
              value={myProfileEmail}
              style={inputStyle}
            />
          </Grid>
        </Grid>

        <br />
        <br />

        <Typography variant="h5" component="div" sx={titleStyle}>
          Preferred contact methods
        </Typography>

        <Grid container style={{ marginTop: "30px" }}>
          {alertTypes.map((alertType) => (
            <Grid item xs={6}>
              <Typography
                variant="h6"
                component="div"
                sx={{ ...titleStyle, fontSize: "13px" }}
              >
                {alertType}
              </Typography>
              {contactMethodTypes.map((contactMethod) => {
                const checkboxState = checkboxStates[alertType]
                  ? checkboxStates[alertType][contactMethod]
                  : false;
                return (
                  <>
                    <Checkbox
                      {...label}
                      checked={checkboxState}
                      onChange={(evt) => {
                        handleCheckboxStateChange(
                          evt,
                          alertType,
                          contactMethod
                        );
                      }}
                      inputProps={{
                        "aria-label": "controlled",
                        id: `${alertType}-${contactMethod}`,
                      }}
                      checkedIcon={<CircleCheckedFilled />}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: checkboxState ? "#84D95B" : "#EAEAEA",
                          fontSize: "28px",
                          backgroundColor: checkboxState
                            ? "transparent"
                            : "#EAEAEA",
                          borderRadius: "500px",
                        },
                      }}
                    />
                    <label
                      for={`${alertType}-${contactMethod}`}
                      style={radioLabelStyle}
                    >
                      {contactMethod}
                    </label>
                  </>
                );
              })}
            </Grid>
          ))}
        </Grid>

        <div
          style={{
            width: "100%",
            marginTop: "97px",
            marginBottom: "97px",
          }}
        >
          <button
            style={{
              background: "#01C1D3 0% 0% no-repeat padding-box",
              borderRadius: "8px",
              fontSize: "13px",
              padding: "13px 44px 18px 44px",
              borderWidth: "0px",
              color: "white",
              display: "block",
              margin: "auto",
              cursor: "pointer",
            }}
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%", "& .MuiSvgIcon-root": { color: "white" } }}
        >
          Saved successfully
        </Alert>
      </Snackbar>
    </div>
  );
}
