import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getAccessToken } from "../helpers/helpers";
import { RISK_LEVELS } from "../helpers/helpers";
import {
  Select,
  FormControl,
  MenuItem,
  Box,
  CircularProgress,
} from "@mui/material";
import { riskLevelBoxStyle } from "../styles/styles";

export default function ContactRiskSelect({ value, contact, handleRefresh }) {
  const { getAccessTokenSilently } = useAuth0();

  const [contactRisk, setContactRisk] = React.useState(value ? value : "Open");
  const [showLoadingSpinner, setShowLoadingSpinner] = React.useState(false);

  const handleChangeContactRisk = async (e) => {
    try {
      const risk = e.target.value;

      setContactRisk(risk);
      setShowLoadingSpinner(true);

      const at = await getAccessToken(getAccessTokenSilently);

      const contactRiskResp = await fetch(
        `${process.env.REACT_APP_API_URL}/contacts/${contact.id}/risk/${risk}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${at}`,
          },
        }
      );

      if (contactRiskResp.ok) {
        setShowLoadingSpinner(false);
        handleRefresh && handleRefresh();
      }
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    setContactRisk(value);
  }, [value]);

  return (
    <>
      {!showLoadingSpinner ? (
        <Box sx={riskLevelBoxStyle}>
          <FormControl fullWidth>
            <Select
              id="contact-risk-select"
              value={contactRisk}
              label="Contact risk"
              onChange={handleChangeContactRisk}
            >
              {RISK_LEVELS.map((encounterRisk) => (
                <MenuItem value={encounterRisk}>{encounterRisk}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      ) : (
        <CircularProgress />
      )}
    </>
  );
}
