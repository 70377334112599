import { useAuth0 } from "@auth0/auth0-react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function PersonDeleteButton({ person }) {

  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
        scope: "openid profile email",
      },
    });

    fetch(`${process.env.REACT_APP_API_URL}/people/${person.id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    })
      .then(async response => {

        if (response.ok) {

          console.log('Person deleted');

          navigate("/people", {
            state: { snackbarSuccess: true, snackbarMessage: "Person deleted", selectedTab: "admin" },
          });

        } else {
          // Handle error case
          console.error('Failed to delete person');
        }
      })
      .catch(error => {
        // Handle network error
        console.error('Error deleting person:', error);
      });

    // Close the modal after successful deletion
    setShowDeleteModal(false);
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
  };


  return (
    <>
      <Button
        variant="contained"
        color="warning"
        onClick={() => handleDeleteClick(person.id)}
      >
        Delete Person...
      </Button>


      <Dialog
        open={showDeleteModal}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-dialog-label"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          {"Delete confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this person?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="warning" onClick={handleDeleteConfirm}>
            Yes
          </Button>
          <Button variant="contained" color="secondary" onClick={handleDeleteCancel} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>

    </>
  )
}
