import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getAccessToken } from "../helpers/helpers";
import { RISK_LEVELS } from "../helpers/helpers";
import {
  Select,
  FormControl,
  MenuItem,
  Box,
  CircularProgress,
} from "@mui/material";
import { riskLevelBoxStyle } from "../styles/styles";

export default function EncounterRiskSelect({
  value,
  encounter,
  handleRefresh,
}) {
  const { getAccessTokenSilently } = useAuth0();

  const [encounterRisk, setEncounterRisk] = React.useState(
    value ? value : "Open"
  );
  const [showLoadingSpinner, setShowLoadingSpinner] = React.useState(false);

  const handleChangeEncounterRisk = async (e) => {
    try {
      const risk = e.target.value;

      setEncounterRisk(risk);
      setShowLoadingSpinner(true);

      const at = await getAccessToken(getAccessTokenSilently);

      const encounterRiskResp = await fetch(
        `${process.env.REACT_APP_API_URL}/encounters/${encounter.id}/risk/${risk}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${at}`,
          },
        }
      );

      if (encounterRiskResp.ok) {
        setShowLoadingSpinner(false);
        handleRefresh && handleRefresh();
      }
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    setEncounterRisk(value ? value : "Open");
  }, [value]);

  return (
    <>
      {!showLoadingSpinner ? (
        <Box sx={riskLevelBoxStyle}>
          <FormControl fullWidth>
            <Select
              id="encounter-risk-select"
              value={encounterRisk}
              label="Encounter risk"
              onChange={handleChangeEncounterRisk}
            >
              {RISK_LEVELS.map((encounterRisk) => (
                <MenuItem value={encounterRisk}>{encounterRisk}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      ) : (
        <CircularProgress />
      )}
    </>
  );
}
