import React from "react";

function PlayIcon({ style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      style={style ? style : {}}
    >
      <path
        id="Polygon_34"
        data-name="Polygon 34"
        d="M6.619,1.645a1,1,0,0,1,1.763,0l5.83,10.882A1,1,0,0,1,13.33,14H1.67a1,1,0,0,1-.881-1.472Z"
        transform="translate(14) rotate(90)"
        fill={style.color ? style.color : "#283555"}
      />
    </svg>
  );
}

export default PlayIcon;
