import { Paper, Grid, Typography, Box, Select, MenuItem } from "@mui/material";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import {
  dashboardWidgetStyle,
  dashboardWidgetTitleStyle,
  mintBg,
  yellowBg,
  yellowText,
  lightRedBg,
  redText,
  statusPillStyle,
  dashboardWidgetContentStyle,
  dashboardWidgetHrStyle,
  dashboardWidgetValueStyle,
  mintText,
  dashboardTableColumnStyle,
  widgetBodyStyle,
} from "../styles/styles";
import React from "react";

function DashboardWidget({ valueColor, cols, widget }) {
  const [filterValue, setFilterValue] = React.useState(widget.lookbackDays);

  const handleFilterChange = (evt, row) => {
    if (widget.onChange) {
      try {
        widget.onChange(evt.target.value);
        setFilterValue(evt.target.value);
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <Grid item xs={12} md={12} sx={{ height: "100%", marginBottom: "14px" }}>
      <Paper
        style={{
          ...dashboardWidgetStyle,
          height: "auto",
          minHeight: widget.height,
        }}
      >
        <Typography sx={dashboardWidgetTitleStyle} variant="h4" component="h4">
          {widget.widgetName}
        </Typography>{" "}
        <div className="widget-body" style={{ ...widgetBodyStyle }}>
          {widget.data.map((row, index) => {
            const status =
              row.data &&
              row.data[row.filter] &&
              row.data[row.filter].status &&
              row.data[row.filter].status;

            return (
              <>
                {index > 0 ? (
                  <div
                    className="horizontal-line-no-margin"
                    style={dashboardWidgetHrStyle}
                  />
                ) : (
                  <></>
                )}
                <div style={dashboardWidgetContentStyle}>
                  {row.data ? (
                    <Grid container>
                      <Grid item xs={12} md={cols.label}>
                        {row.label}
                      </Grid>
                      <Grid item xs={12} md={cols.value}>
                        <div style={dashboardTableColumnStyle}>
                          <div
                            style={{
                              ...dashboardWidgetValueStyle,
                              ...valueColor,
                            }}
                          >
                            {row.data[row.filter].value}
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={cols.status}>
                        <div style={dashboardTableColumnStyle}>
                          <div
                            style={{
                              ...statusPillStyle,
                              ...(status === "improving" && mintBg),
                              ...(status === "improving" && mintText),
                              ...(status === "same" && yellowBg),
                              ...(status === "same" && yellowText),
                              ...(status === "worse" && lightRedBg),
                              ...(status === "worse" && redText),
                            }}
                          >
                            {status}
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={cols.filter}>
                        <div style={dashboardTableColumnStyle}>
                          <Select
                            sx={{
                              background: "#F7FAFF",
                              borderRadius: 24,
                              height: "30px",
                            }}
                            value={filterValue}
                            onChange={(evt) => handleFilterChange(evt, row)}
                            IconComponent={KeyboardArrowDownIcon}
                          >
                            <MenuItem value={1}>Last 24 hours</MenuItem>
                            <MenuItem value={7}>Last 7 days</MenuItem>
                            <MenuItem value={30}>Last month</MenuItem>
                          </Select>
                        </div>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container>
                      {row.value !== undefined ? (
                        <>
                          <Grid item xs={12} md={cols.label}>
                            {row.label}
                          </Grid>
                          <Grid item xs={12} md={cols.value}>
                            <div style={dashboardTableColumnStyle}>
                              <div
                                style={{
                                  ...dashboardWidgetValueStyle,
                                  ...valueColor,
                                }}
                              >
                                {row.value}
                              </div>
                            </div>
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  )}
                </div>
              </>
            );
          })}
        </div>
      </Paper>
    </Grid>
  );
}

export default DashboardWidget;
