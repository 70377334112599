import React from "react";

function FastForwardIcon({ style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="15"
      viewBox="0 0 18 15"
      style={style ? style : {}}
    >
      <g
        id="Group_17113"
        data-name="Group 17113"
        transform="translate(-516 -610)"
      >
        <path
          id="Polygon_29"
          data-name="Polygon 29"
          d="M6.674,1.212a1,1,0,0,1,1.652,0l5.608,8.225A1,1,0,0,1,13.108,11H1.892a1,1,0,0,1-.826-1.563Z"
          transform="translate(527 610) rotate(90)"
          fill={style.color ? style.color : "#283555"}
        />
        <path
          id="Polygon_30"
          data-name="Polygon 30"
          d="M6.674,1.212a1,1,0,0,1,1.652,0l5.608,8.225A1,1,0,0,1,13.108,11H1.892a1,1,0,0,1-.826-1.563Z"
          transform="translate(534 610) rotate(90)"
          fill={style.color ? style.color : "#283555"}
        />
      </g>
    </svg>
  );
}

export default FastForwardIcon;
