import React from "react";

function HeaderMagnifyingGlassIcon({ style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      style={style ? style : {}}
    >
      <g
        id="Group_17137"
        data-name="Group 17137"
        transform="translate(-1013 -16)"
      >
        <circle
          id="Ellipse_148"
          data-name="Ellipse 148"
          cx="20"
          cy="20"
          r="20"
          transform="translate(1013 16)"
          fill="#eaf0ff"
        />
        <path
          id="Search_Icon"
          data-name="Search Icon"
          d="M15.081,13.335a8.047,8.047,0,0,0,1.662-4.957A8.336,8.336,0,0,0,8.431,0,8.438,8.438,0,0,0,0,8.379a8.438,8.438,0,0,0,8.431,8.379,7.917,7.917,0,0,0,4.988-1.652l3.563,3.54a1.153,1.153,0,0,0,1.662,0,1.136,1.136,0,0,0,0-1.652Zm-6.65.944a5.963,5.963,0,0,1-6.056-5.9,6.056,6.056,0,0,1,12.112,0A5.963,5.963,0,0,1,8.431,14.28Z"
          transform="translate(1023 26)"
          fill="#1763f7"
          stroke="#118be2"
          stroke-width="0.3"
        />
      </g>
    </svg>
  );
}

export default HeaderMagnifyingGlassIcon;
