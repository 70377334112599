import { Paper, Grid } from "@mui/material";
import {
  dashboardWidgetStyle,
  blueText,
  greenBg,
  redBg,
  greenGlow,
  redGlow,
  statusYellowBg,
  statusYellowGlow,
  widgetBodyStyle,
  glowingDotStyle,
  alertWidgetValueStyle,
  alertWidgetLabelStyle,
  alertWidgetStyle,
  alertWidgetStatusStyle,
  alertWidgetHrStyle,
  alertWidgetContentStyle,
  alertWidgetContentContainerStyle,
} from "../styles/styles";

function WearableAlertWidget({ widget }) {
  return (
    <Grid
      item
      xs={12}
      md={12}
      sx={{ height: "100%", width: "100%", marginBottom: "14px" }}
    >
      <Paper style={dashboardWidgetStyle}>
        <div
          className="widget-body"
          style={{ ...widgetBodyStyle, ...alertWidgetStyle }}
        >
          {widget.data.map((row, index) => {
            const status = row.status;

            return (
              <>
                <div
                  style={{
                    ...alertWidgetContentContainerStyle,
                  }}
                >
                  {index > 0 ? (
                    <div className="vertical-line" style={alertWidgetHrStyle} />
                  ) : (
                    <></>
                  )}
                  <div style={alertWidgetContentStyle}>
                    <Grid container>
                      <Grid item xs={10}>
                        <div style={alertWidgetLabelStyle}>{row.label}</div>
                      </Grid>
                      <Grid item xs={2}>
                        <div style={alertWidgetStatusStyle}>
                          <div
                            style={{
                              ...glowingDotStyle,
                              ...(status === "green" && greenBg),
                              ...(status === "green" && greenGlow),
                              ...(status === "red" && redBg),
                              ...(status === "red" && redGlow),
                              ...(status === "yellow" && statusYellowBg),
                              ...(status === "yellow" && statusYellowGlow),
                            }}
                          ></div>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div>
                          <div
                            style={{ ...alertWidgetValueStyle, ...blueText }}
                          >
                            {row.value}
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={12}>
                        <div>System alerts</div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </Paper>
    </Grid>
  );
}

export default WearableAlertWidget;
