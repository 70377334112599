import * as React from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import "../style.css";
import Typography from "@mui/material/Typography";
import FormattedDate from "../../../FormattedDate/FormattedDate";
import Grid from "@mui/material/Grid";
import { titleStyle, containerStyle } from "../../../styles/styles";
import { TransitionGroup } from "react-transition-group";
import Collapse from "@mui/material/Collapse";

export default function ShowOutstandingNotifications({ user }) {
  const [data, setData] = React.useState();
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [count, setCount] = React.useState();

  const severity = {
    1: "warning",
    default: "info",
  };

  const fetchUnreadNotifications = async () => {
    const resp = await fetch(
      `${process.env.REACT_APP_RTLS_URL}/notifications/unread?clientId=${user.clientId}&pageSize=${pageSize}&page=${page}`
    );
    const data = await resp.json();

    setCount(Math.ceil(parseInt(data.count) / pageSize));

    setData(
      data.results.map((notification) => {
        let type = notification.notification_type_id;

        if (!severity[notification.notification_type_id]) {
          type = "default";
        }

        return {
          id: notification.notification_id,
          severity: severity[type],
          content: notification.short_description,
        };
      })
    );
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleReadNotification = async (notification) => {
    const resp = await fetch(
      `${process.env.REACT_APP_RTLS_URL}/notifications/${notification.id}/read?clientId=${user.clientId}`,
      { method: "PUT" }
    );
    const result = await resp.json();

    if (result.result === "success") {
      setData(
        data.filter((n) => {
          return n.id !== notification.id;
        })
      );
    }
  };

  React.useEffect(() => {
    if (!data) {
      fetchUnreadNotifications();
    }
  }, []);

  React.useEffect(() => {
    fetchUnreadNotifications();
  }, [page, pageSize]);

  return (
    <div>
      <Box style={containerStyle}>
        <FormattedDate />

        <Typography
          variant="h5"
          component="div"
          sx={{ ...titleStyle, mb: "20px" }}
        >
          Outstanding Notifications
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <Stack sx={{ width: "100%" }} spacing={2}>
              <TransitionGroup>
                {data &&
                  data.map((notification) => (
                    <Collapse key={notification.id}>
                      <Alert
                        sx={{ marginTop: 1, marginBottom: 1 }}
                        severity={notification.severity}
                        onClose={() => handleReadNotification(notification)}
                      >
                        {notification.content}
                      </Alert>
                    </Collapse>
                  ))}
              </TransitionGroup>
            </Stack>
            <br />
            {count !== undefined ? (
              <Pagination
                count={count}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
            ) : (
              <></>
            )}
            <br />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
