import * as React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import "../../components/MainContainer/HomeTabs/style.css";
import { textLinkStyle } from "../../components/styles/styles";

const goBackStyle = {
  color: "#8f9ab5",
  fontSize: 14,
  marginBottom: 1.5,
  display: "inline-flex",
  alignItems: "center",
};

const goBackArrowStyle = {
  fontSize: "12px",
  color: "#909090",
  marginRight: "10px",
};

export default function GoBack() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Button style={textLinkStyle} onClick={handleGoBack}>
      <Typography sx={goBackStyle}>
        <ArrowBackIosNewIcon sx={goBackArrowStyle} />
        Go back
      </Typography>
    </Button>
  );
}
