import { Box, Button, Grid, InputLabel, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
// NOTE: These are two different things. The first Checkbox is the Checkbox component which includes onClick, and the 2nd CheckBox is just an SVG icon from the icons-material package.
import { useAuth0 } from "@auth0/auth0-react";
import MuiAlert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import FormHelperText from "@mui/material/FormHelperText";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import GoBack from "../../GoBack/GoBack";
import PersonDeleteButton from "../../people/PersonDeleteButton";
import {
  autocompleteInputStyle,
  containerStyle,
  datePickerStyle,
  inputLabelStyle,
  inputStyle,
  titleStyle,
} from "../../styles/styles";
import "../HomeTabs/style.css";

export default function PersonEditor({ tags, refreshTags }) {
  const { getAccessTokenSilently } = useAuth0();

  const tagStartDateEditable = false;

  const { state } = useLocation();
  const navigate = useNavigate();
  const { editedPerson: initialPerson } = state;

  const [dateTimeStart, setDateTimeStart] = React.useState(
    dayjs(new Date())//.subtract(1, "week")
  );

  const [roles, setRoles] = React.useState();
  const [firstnameError, setFirstnameError] = React.useState(false);
  const [lastnameError, setLastnameError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [nhsError, setNhsError] = React.useState(false);
  const [dateTimeStartError, setDateTimeStartError] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const [person, setPerson] = React.useState(initialPerson);
  const [email, setEmail] = React.useState(
    initialPerson ? initialPerson.email : null
  );
  const [nhsNumber, setNhsNumber] = React.useState(
    initialPerson ? initialPerson.healthcareIdentifier : null
  );
  const [firstName, setFirstName] = React.useState(
    initialPerson ? initialPerson.firstName : null
  );
  const [lastName, setLastName] = React.useState(
    initialPerson ? initialPerson.lastName : null
  );
  const [role, setRole] = React.useState(
    initialPerson ? initialPerson.role : null
  );

  const tagOptions = [
    { label: "-- No tag assigned --", value: null }, // Null option
    ...tags?.map((tag) => ({
      label: `${tag.serial}${tag.person ? ` -- ${tag.person}` : ""}`,
      value: tag.serial,
    })),
  ];

  const [selectedTag, setSelectedTag] = React.useState(() => {
    const serial =
      person?.tags.find((ptag) =>
        tags?.some((tag) => tag.serial === ptag.serial)
      )?.serial ?? null;
    const tagOption = tagOptions.find((option) => option.value === serial);
    return tagOption;
  });

  const usingEmail = ["Staff", "Visitor"].includes(role);
  const usingNhsNumber = ["Patient"].includes(role);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const fetchRoles = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
        scope: "openid profile email",
      },
    });

    const roleData = await fetch(
      `${process.env.REACT_APP_API_URL}/people/roles`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const roles = await roleData?.json();

    setRoles(
      roles.map((role) => ({
        token: role.token,
      }))
    );
  };

  React.useEffect(() => {
    if (!roles) {
      fetchRoles();
    }
  }, []);

  const isFormValid = () => {
    let errors = false;

    if (!firstName || firstName.length === 0) {
      setFirstnameError(true);
      errors = true;
    } else {
      setFirstnameError(false);
    }

    if (!lastName || lastName.length === 0) {
      setLastnameError(true);
      errors = true;
    } else {
      setLastnameError(false);
    }

    if (emailError) {
      errors = true;
    }

    if (nhsError) {
      errors = true;
    }

    if (!dateTimeStart) {
      setDateTimeStartError(true);
      errors = true;
    } else {
      setDateTimeStartError(false);
    }

    return errors ? false : true;
  };

  const handleSave = async () => {
    try {
      let response;

      if (!isFormValid()) {
        return false;
      }

      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
          scope: "openid profile email",
        },
      });

      const selectedTagSerial =
        selectedTag.value !== null ? selectedTag.value : null;
      const selectedTagAssignmentStart =
        selectedTag.value !== null
          ? new Date(dateTimeStart.toISOString())
          : null;

      if (person) {
        const updateModel = {
          id: person.id,
          role: role,
          firstName: firstName,
          lastName: lastName,
          email: usingEmail ? email : null,
          healthcareIdentifier: usingNhsNumber ? nhsNumber : null,
          tagSerial: selectedTagSerial,
          tagAssignmentStart: selectedTagAssignmentStart,
        };

        response = await fetch(
          `${process.env.REACT_APP_API_URL}/people/${person.id}`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updateModel),
          }
        );
      } else {
        const createModel = {
          role: role,
          firstName: firstName,
          lastName: lastName,
          email: usingEmail ? email : null,
          healthcareIdentifier: usingNhsNumber ? nhsNumber : null,
          tagSerial: selectedTagSerial,
          tagAssignmentStart: selectedTagAssignmentStart,
        };

        response = await fetch(`${process.env.REACT_APP_API_URL}/people`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(createModel),
        });
      }

      const data = await response.json();
      console.log("data", data);

      if (response.ok) {
        setPerson(data);

        navigate("/people", {
          state: { snackbarSuccess: true, selectedTab: "admin" },
        });

        setSnackbarOpen(true);
      } else {
        debugger;

        if (data.errorCode) {
          if (data.errorCode === 12002) {
            //PersonUniqueEmailViolation
            setEmailError(true);
            return false;
          }

          if (data.errorCode === 12001) {
            //PersonUniqueHealthcareIdentifierViolation
            setNhsError(true);
            return false;
          }
        }
        console.error("Error:", data.error);
        // What do we do with an error we can't handle?
      }
    } catch (error) {
      debugger;
      console.error("Error:", error);
      // What do we do with an error we can't handle?
    } finally {
      refreshTags();
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleEmailChange = (evt) => {
    setEmail(evt.target.value);
  };

  const handleNhsNumberChange = (evt) => {
    setNhsError(false); // reset error
    setNhsNumber(evt.target.value);
  };

  const handleDateTimeStartChange = (string) => {
    // setDateTime(moment(string.toString()).format("YYYY-MM-DD HH:mm:ss"));
    setDateTimeStart(dayjs(string));
  };

  const handleLastNameChange = (evt) => {
    setLastName(evt.target.value);
  };

  const handleFirstNameChange = (evt) => {
    setFirstName(evt.target.value);
  };

  const handleRoleChange = (evt) => {
    setRole(evt.target.value);
  };

  const validateFirstName = () => {
    if (firstName && firstName.length === 0) {
      setFirstnameError(true);
    } else {
      setFirstnameError(false);
    }
  };

  const validateLastName = () => {
    if (lastName && lastName.length === 0) {
      setLastnameError(true);
    } else {
      setLastnameError(false);
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const trimmedEmail = email.trim(); // Remove leading/trailing spaces
    return emailRegex.test(trimmedEmail);
  };

  const validateEmail = () => {
    if (usingEmail && email && email.length > 0 && isValidEmail(email)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  return (
    <>
      <Box style={containerStyle}>
        <GoBack />

        <br></br>
        <Typography variant="h5" component="div" sx={titleStyle}>
          {person ? "Manage person" : "Add new person"}
        </Typography>

        <Grid container spacing={2} style={{ marginTop: "30px" }}>
          <Grid item xs={3.8}>
            <InputLabel htmlFor="firstname" sx={inputLabelStyle}>
              First name
            </InputLabel>

            <input
              type="text"
              id="firstname"
              name="firstname"
              onChange={handleFirstNameChange}
              onBlur={validateFirstName}
              value={firstName}
              style={inputStyle}
            />
            <FormHelperText error={firstnameError} id="first-name-helper-text">
              {firstnameError ? "First name is required" : ""}
            </FormHelperText>
          </Grid>

          <Grid item xs={3.8}>
            <InputLabel htmlFor="lastname" sx={inputLabelStyle}>
              Last name
            </InputLabel>

            <input
              type="text"
              id="lastname"
              name="lastname"
              onChange={handleLastNameChange}
              onBlur={validateLastName}
              value={lastName}
              style={inputStyle}
            />

            <FormHelperText error={lastnameError} id="last-name-helper-text">
              {lastnameError ? "Last name is required" : ""}
            </FormHelperText>
          </Grid>
        </Grid>

        <div
          className="horizontal-line"
          style={{ margin: "28px 0px 28px 0px" }}
        ></div>

        <Grid container spacing={2} style={{ marginTop: "30px" }}>
          <Grid item xs={3.8}>
            <label htmlFor="role" style={inputLabelStyle}>
              Role
            </label>
            <br />
            <select
              id="role"
              value={role}
              onChange={handleRoleChange}
              name="role"
              style={inputStyle}
            >
              <option value={false}> </option>

              {roles ? (
                roles.map((role, index) => (
                  <option key={index} value={role.token}>
                    {role.token}
                  </option>
                ))
              ) : (
                <></>
              )}
            </select>
          </Grid>

          {usingNhsNumber ? (
            <Grid item xs={3.8}>
              <InputLabel htmlFor="nhs" sx={inputLabelStyle}>
                NHS Number
              </InputLabel>

              <input
                type="text"
                id="nhsNumber"
                name="NHS Number"
                onChange={handleNhsNumberChange}
                value={nhsNumber}
                style={inputStyle}
              />

              <FormHelperText error={nhsError} id="nhs-helper-text">
                {nhsError ? "Unique NHS number is required" : ""}
              </FormHelperText>
            </Grid>
          ) : null}

          {usingEmail ? (
            <Grid item xs={3.8}>
              <InputLabel htmlFor="email" sx={inputLabelStyle}>
                Email
              </InputLabel>

              <input
                type="text"
                id="email"
                name="email"
                onChange={handleEmailChange}
                onBlur={validateEmail}
                value={email}
                style={inputStyle}
              />

              <FormHelperText error={emailError} id="email-helper-text">
                {emailError ? "Valid email is required" : ""}
              </FormHelperText>
            </Grid>
          ) : null}
        </Grid>

        <div
          className="horizontal-line"
          style={{ margin: "28px 0px 28px 0px" }}
        ></div>

        <Grid container>
          <Grid item xs={3.8}>
            <label htmlFor="tag" style={inputLabelStyle}>
              Current Tag
            </label>

            <Autocomplete
              options={tagOptions}
              id="tag"
              value={selectedTag}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={(event, selectedOption) => {
                if (selectedOption) {
                  setSelectedTag(selectedOption);
                } else {
                  console.log("No value selected");
                }
              }}
              name="tag"
              style={autocompleteInputStyle}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>

          <Grid item xs={3.8}>
            <label htmlFor="startdate" style={inputLabelStyle}>
              Worn since
            </label>
            <br />
            {tagStartDateEditable ? (
              <>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  localeText={{
                    okButtonLabel: "Save",
                    todayButtonLabel: "Set date & time to now",
                  }}
                >
                  <DateTimePicker
                    id="startdate"
                    sx={{
                      ...inputStyle,
                      ...datePickerStyle,
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    }}
                    format="DD-MM-YYYY h:mm A"
                    slotProps={{
                      actionBar: { actions: ["today", "accept"] },
                    }}
                    value={dateTimeStart}
                    onChange={handleDateTimeStartChange}
                  />
                </LocalizationProvider>
                <FormHelperText
                  error={dateTimeStartError}
                  id="date-time-start-helper-text"
                >
                  {dateTimeStartError ? "Tag start date is required" : ""}
                </FormHelperText>
              </>
            ) : (
              <input
                type="text"
                id="startdate"
                name="startdate"
                disabled
                onChange={handleEmailChange}
                value={dateTimeStart.format("hh:mm on dddd, DD MMMM YYYY")}
                style={inputStyle}
              />
            )}
          </Grid>

          <div
            className="horizontal-line"
            style={{ margin: "28px 0px 28px 0px" }}
          ></div>
        </Grid>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <Button variant="contained" color="primary" onClick={handleSave}>
            {person ? "Save changes" : "Add person"}
          </Button>

          {person && <PersonDeleteButton person={person} />}
        </div>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%", "& .MuiSvgIcon-root": { color: "white" } }}
        >
          Saved successfully
        </Alert>
      </Snackbar>
    </>
  );
}
