import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { saveAs } from "file-saver";
import ICSReportPdf from "../../../PdfTemplates/InfectionControlSummaryPdf";
import reactElementToJSXString from "react-element-to-jsx-string";
import "../style.css";
import {
  titleStyle,
  buttonTextStyle,
  buttonStyle,
  cyanBg,
  containerStyle,
} from "../../../styles/styles";
import { Document, usePDF } from "@react-pdf/renderer";
import StarFilledIcon from "../../../StarFilledIcon/StarFilledIcon";
import StarEmptyIcon from "../../../StarEmptyIcon/StarEmptyIcon";
import FormattedDate from "../../../../components/FormattedDate/FormattedDate";

const reportsColumnStyle = {
  padding: "20px",
};

const reportsColumnDivStyle = {
  background: "white",
  marginBottom: "12px",
  borderRadius: "8px",
  overflow: "hidden",
};

const reportsRowStyle = {
  padding: "10px",
};

const reportsRowButtonStyle = {
  color: "#333",
  fontWeight: 400,
  display: "inline-flex",
  fontSize: 12,
  fontFamily: "Open Sans",
  textDecoration: "underline",
  width: "100%",
  justifyContent: "flex-start",
};

const reportsColumnHeaderStyle = {
  backgroundColor: "#F0F2F6",
  padding: "10px",
  fontSize: 12,
  fontWeight: 600,
  fontFamily: "Open Sans",
  color: "#777F9E",
  margin: 0,
  width: "auto",
};

const reportsColumnHeaderItemStyle = {
  padding: "5px 8px",
};

export default function Reports({ user, pdfHeaderImage }) {
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [pathogens, setPathogens] = React.useState();
  const [infectionCases, setInfectionCases] = React.useState();
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [infectionCasesPDFInstance, updateInfectionCasesPDF] = usePDF(
    <Document></Document>
  );
  const [favorites, setFavorites] = React.useState();
  const [infectionSafe, setInfectionSafe] = React.useState();

  const renderInfectionCasesPdf = async () => {
    const reportIdResp = await fetch(
      `${process.env.REACT_APP_RTLS_URL}/report-id`
    );
    const reportIdJson = await reportIdResp.json();
    const reportId = reportIdJson.id;

    const filteredInfectionCases = infectionCases.filter((row) => {
      const twoWeeksAgo = moment(new Date()).subtract(14, "days").format();
      const twoWeeksAgoTimestamp = new Date(twoWeeksAgo).getTime();
      const onsetTimestamp = new Date(row.onsetTime).getTime();
      if (onsetTimestamp >= twoWeeksAgoTimestamp) {
        return row;
      }
      return false;
    });

    const pdf = ICSReportPdf(
      user.firstName + " " + user.lastName,
      user.email,
      pdfHeaderImage,
      filteredInfectionCases,
      pathogens,
      reportId
    );

    updateInfectionCasesPDF(pdf);

    await fetch(`${process.env.REACT_APP_RTLS_URL}/reports/${reportId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        reportName: `Proxximos-ICS-${moment(new Date()).format("LLL")}.pdf`,
        reportContent: encodeURI(reactElementToJSXString(pdf)),
        userId: user.userId,
      }),
    });
  };

  React.useEffect(() => {
    if (infectionCasesPDFInstance && infectionCasesPDFInstance.blob) {
      saveAs(
        infectionCasesPDFInstance.blob,
        `Proxximos-ICS-${moment(new Date()).format("LLL")}.pdf`
      );
    }
  }, [infectionCasesPDFInstance]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const fetchInfectionCases = async () => {
    const pathogenData = await fetch(
      `${process.env.REACT_APP_RTLS_URL}/pathogens?clientId=${user.clientId}`
    );
    const pathogenDataJson = await pathogenData.json();

    const pathogenArray = pathogenDataJson.results;

    const formattedPathogenArray = pathogenArray.map((pathogen) => ({
      id: pathogen.pathogen_id,
      pathogenName: pathogen.pathogen_name,
      pathogenShortDesc: pathogen.pathogen_short_desc,
      pathogenLongDesc: pathogen.pathogen_long_desc,
      isAirborne: pathogen.is_airborne,
      isDroplet: pathogen.is_droplet,
      isIndirect: pathogen.is_indirect,
      isDirect: pathogen.is_direct,
      distanceBoundaryCm: pathogen.distance_boundary_cm,
      timeBoundaryMinutes: pathogen.time_boundary_minutes,
      indirectLingerTime: pathogen.indirect_linger_time,
      infectionSite: pathogen.infection_site,
    }));

    setPathogens(formattedPathogenArray);

    const exposuresData = await fetch(
      `${process.env.REACT_APP_RTLS_URL}/infections?clientId=${user.clientId}`
    );
    const exposuresDataJson = await exposuresData.json();
    const exposuresArray = exposuresDataJson.exposures;
    const formattedArray = exposuresArray.map((exposure) => {
      const actionsString = JSON.parse(exposure.actions_for_case)
        .map((action) => action.value)
        .join(", ");

      return {
        id: exposure.infection_id,
        firstName: exposure.first_name,
        lastName: exposure.last_name,
        status: exposure.status,
        onsetTime: exposure.timestamp_of_onset,
        pathogenId: exposure.pathogen_id,
        infectionId: exposure.infection_id,
        exposureType: exposure.infection_type,
        actions: exposure.actions_for_case,
        actionsString: actionsString,
        exposedContacts: exposure.number_of_exposed_contacts,
        contaminatedRooms: exposure.number_of_contaminated_rooms,
      };
    });

    setInfectionCases(formattedArray);
  };

  React.useEffect(() => {
    if (!infectionCases) {
      fetchInfectionCases();
    }
  }, []);

  React.useEffect(() => {
    setFavorites([
      {
        name: "Infection Cases",
        pdf: renderInfectionCasesPdf,
      },
      {
        name: "Cohort integrity",
      },
    ]);
  }, [infectionCases]);

  React.useEffect(() => {
    setInfectionSafe([
      {
        name: "Infection Cases",
        pdf: renderInfectionCasesPdf,
        star: true,
      },
      {
        name: "Exposed People",
        link: "/reports/under-construction",
        star: false,
      },
      {
        name: "Cohort Integrity",
        link: "/reports/under-construction",
        star: true,
      },
      {
        name: "Cases and Exposure Trends",

        link: "/reports/under-construction",
        star: false,
      },
      {
        name: "Contaminated Places and Cleaning",
        link: "/reports/under-construction",

        star: false,
      },
      {
        name: "Isolated People Integrity",
        link: "/reports/under-construction",

        star: false,
      },
    ]);
  }, [infectionCases]);

  return (
    <div>
      <Box style={containerStyle}>
        <Grid
          container
          spacing={2}
          style={{ marginTop: "30px", padding: "0px 20px" }}
        >
          <Grid item xs={12} md={6}>
            <FormattedDate />

            <Typography variant="h5" component="div" sx={titleStyle}>
              Reports
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <div
              style={{
                width: "100%",
                display: "inline-flex",
                justifyContent: "flex-end",
                alignContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <button
                className="add-new-infection-button"
                style={{
                  ...buttonStyle,
                  ...cyanBg,
                  width: "auto",
                  justifyContent: "flex-end",
                  display: "inline-flex",
                  marginTop: "40px",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "top",
                    flexWrap: "wrap",
                  }}
                >
                  <a
                    href={`mailto:info@proxximos.com?subject=New report request from client #${user.clientId}`}
                    target="_blank"
                  >
                    <Typography sx={buttonTextStyle}>
                      Request new report
                    </Typography>
                  </a>
                </div>
              </button>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={0} style={{ marginTop: "0px" }}>
          <Grid item xs={12} md={3} style={reportsColumnStyle}>
            <div style={reportsColumnDivStyle}>
              {" "}
              <Grid container spacing={2} style={reportsColumnHeaderStyle}>
                <Grid item xs={12} style={reportsColumnHeaderItemStyle}>
                  Infection safe
                </Grid>
              </Grid>
              {infectionSafe ? (
                infectionSafe.map((item, index) => (
                  <div>
                    {index !== 0 ? (
                      <div class="horizontal-line-no-margin"></div>
                    ) : (
                      <></>
                    )}

                    <Grid
                      container
                      style={reportsRowStyle}
                      spacing={2}
                      alignItems="center"
                    >
                      <Grid item xs={10}>
                        {item.pdf ? (
                          <Button
                            onClick={item.pdf}
                            style={reportsRowButtonStyle}
                          >
                            <>{item.name}</>
                          </Button>
                        ) : (
                          <>
                            {item.link ? (
                              <Button style={reportsRowButtonStyle}>
                                <Link to={item.link}>{item.name}</Link>
                              </Button>
                            ) : (
                              <>{item.name}</>
                            )}
                          </>
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        {item.star ? (
                          <StarFilledIcon />
                        ) : (
                          <StarEmptyIcon style={{ color: "#E0E0E0" }} />
                        )}
                      </Grid>
                    </Grid>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          </Grid>
        </Grid>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%", "& .MuiSvgIcon-root": { color: "white" } }}
        >
          Saved successfully
        </Alert>
      </Snackbar>
    </div>
  );
}
