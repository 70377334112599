import * as React from "react";
import Typography from "@mui/material/Typography";
import "../../components/MainContainer/HomeTabs/style.css";
import { goBackStyle } from "../../components/styles/styles";
import { formatDate } from "../helpers/helpers";

export default function FormattedDate() {
  const inputDate = new Date();
  const formattedDate = formatDate(inputDate);
  return <Typography sx={goBackStyle}>{formattedDate}</Typography>;
}
