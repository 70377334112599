import React from "react";
import { Grid, Typography, Box, CircularProgress } from "@mui/material";
import DashboardWidget from "../DashboardWidget/DashboardWidget";
import randomColor from "randomcolor";
import ChartWidget from "../ChartWidget/ChartWidget";
import {
  containerStyle,
  titleStyle,
  mainContainerNavStyle,
  cyanText,
  blueText,
} from "../styles/styles";
import WearableAlertWidget from "../WearableAlertWidget/WearableAlertWidget";
import FormattedDate from "../FormattedDate/FormattedDate";
import { filter } from "d3";
import { Chart as ChartJS } from "chart.js/auto";

function HomeTemplate({ user }) {
  const widgetColWidths = {
    "Infection safe": {
      label: 5,
      value: 2,
      status: 2,
      filter: 3,
    },
    "Team safe": {
      label: 10,
      value: 2,
    },
    "Resident safe": {
      label: 10,
      value: 2,
    },
    "Asset safe": {
      label: 10,
      value: 2,
    },
  };

  const INFECTION_SAFE_HEIGHT = "377px";
  const ASSET_SAFE_HEIGHT = "367px";

  const [infectionSafe, setInfectionSafe] = React.useState();
  const [
    infectionSafeTrendAnalysisLookbackDays,
    setInfectionSafeTrendAnalysisLookbackDays,
  ] = React.useState(7);
  const [infectionSafeTrendAnalysis, setInfectionSafeTrendAnalysis] =
    React.useState();
  const [
    infectionSafeTrendAnalysisFilter,
    setInfectionSafeTrendAnalysisFilter,
  ] = React.useState("Last 7 days");
  const [infectionSafeFilter, setInfectionSafeFilter] =
    React.useState("Last 7 days");

  const [systemAlerts, setSystemAlerts] = React.useState();
  const [infectionSafeLookbackDays, setInfectionSafeLookbackDays] =
    React.useState(7);
  const [widgets, setWidgets] = React.useState();
  const [graphWidgets, setGraphWidgets] = React.useState();
  const interval = React.useRef();

  const fetchInfectionSafe = async () => {
    try {
      const data = await fetch(
        `${process.env.REACT_APP_RTLS_URL}/infectionSafeWidget?userId=${user.userId}&lookbackDays=${infectionSafeLookbackDays}`
      );
      const dataJson = await data.json();

      const dataArray = dataJson.results;

      const widget = dataArray[0];

      const widgetModel = [
        {
          label: "Infection cases",
          filter: infectionSafeFilter,
          data: {
            [infectionSafeFilter]: {
              value: widget.infection_cases,
              status: widget.infection_cases_recent_trend.toLowerCase(),
            },
          },
        },
        {
          label: "Infection close contacts",
          filter: infectionSafeFilter,
          data: {
            [infectionSafeFilter]: {
              value: widget.infection_close_contacts,
              status:
                widget.infection_close_contacts_recent_trend.toLowerCase(),
            },
          },
        },
        {
          label: "Contaminated locations",
          filter: infectionSafeFilter,
          data: {
            [infectionSafeFilter]: {
              value: widget.contaminated_locations,
              status: widget.contaminated_locations_recent_trend.toLowerCase(),
            },
          },
        },
        {
          label: "Outstanding actions",
          value: widget.outstanding_actions,
        },
      ];

      return {
        widgetName: "Infection safe",
        lookbackDays: infectionSafeLookbackDays,
        onChange: (newValue) => {
          setInfectionSafeFilter(`Last ${newValue} days`);
          setInfectionSafeLookbackDays(newValue);
        },
        data: widgetModel,
        height: INFECTION_SAFE_HEIGHT,
      };
    } catch (e) {
      console.log(e);
    }
  };

  const getStatus = (chartData) => {
    let oldestDaySums = 0;
    let todaySums = 0;
    let status;

    chartData.map((row, index) => {
      row.map((day, index) => {
        if (index === 0) {
          oldestDaySums += day;
        }

        if (index === row.length - 1) {
          todaySums += day;
        }
      });
    });

    if (oldestDaySums < todaySums) {
      status = "worse";
    }

    if (oldestDaySums > todaySums) {
      status = "better";
    }

    if (oldestDaySums === todaySums) {
      status = "same";
    }

    return status;
  };

  const fetchInfectionSafeTrendAnalysis = async () => {
    try {
      const data = await fetch(
        `${process.env.REACT_APP_RTLS_URL}/infectionSafeTrendAnalysisWidget?userId=${user.userId}&lookbackDays=${infectionSafeTrendAnalysisLookbackDays}`
      );
      const dataJson = await data.json();

      const dataArray = dataJson.results;

      const infectionsTrendLine = [];
      const exposureTrendLine = [];
      const locationsTrendLine = [];
      const dates = [];

      dataArray.map((day) => {
        infectionsTrendLine.push(day.infections_trend_line);

        exposureTrendLine.push(day.exposures_trend_line);

        locationsTrendLine.push(day.locations_trend_line);

        dates.push(day.start_date);
      });

      const chartData = [
        infectionsTrendLine,
        exposureTrendLine,
        locationsTrendLine,
      ];

      const status = getStatus(chartData);

      const widgetModel = {
        filter: infectionSafeTrendAnalysisFilter,
        filterData: {
          [infectionSafeTrendAnalysisFilter]: {
            status,
          },
        },
        chartDates: dates,
        chartData,
        chartLabels: [
          "Infections count daily",
          "Exposures per day",
          "Locations per day",
        ],
        chartColors: [randomColor(), randomColor(), randomColor()],
      };

      return {
        widgetName: "Infection Safe Trend Analysis",
        lookbackDays: infectionSafeTrendAnalysisLookbackDays,
        onChange: (newValue) => {
          setInfectionSafeLookbackDays(newValue);
          setInfectionSafeFilter(`Last ${newValue} days`);
        },
        data: widgetModel,
        height: INFECTION_SAFE_HEIGHT,
      };
    } catch (e) {
      console.log(e);
    }
  };

  const fetchSystemAlerts = async () => {
    try {
      const data = await fetch(
        `${process.env.REACT_APP_RTLS_URL}/systemAlertsWidget?userId=${user.userId}`
      );
      const dataJson = await data.json();

      const dataArray = dataJson.results;

      const widget = dataArray[0];

      const widgetModel = [
        {
          label: "Low battery life",
          status:
            widget.LOW_BATTERY_LIFE > 80
              ? "green"
              : widget.LOW_BATTERY_LIFE < 15
              ? "red"
              : "yellow",
          value: widget.LOW_BATTERY_LIFE,
        },
        {
          label: "Dead battery or missing wearables",
          status:
            widget.DEAD_BATTERY_OR_MISSING_WEARABLES > 0 ? "red" : "green",
          value: widget.DEAD_BATTERY_OR_MISSING_WEARABLES,
        },
        {
          label: "Static wearables",
          status: widget.STATIC_WEARABLES > 0 ? "red" : "green",
          value: widget.STATIC_WEARABLES,
        },
      ];

      return {
        data: widgetModel,
      };
    } catch (e) {
      console.log(e);
    }
  };

  const fetchWidgets = async () => {
    if (!user) {
      return;
    }

    let ts, tsta;

    const is = await fetchInfectionSafe();
    const ista = await fetchInfectionSafeTrendAnalysis();
    const sa = await fetchSystemAlerts();

    setInfectionSafe(is);
    setInfectionSafeTrendAnalysis(ista);
    setSystemAlerts(systemAlerts);

    if (user.isCareHome) {
      setGraphWidgets([tsta, ista]);
    } else {
      setGraphWidgets([ista]);
    }

    if (user.isCareHome) {
      setWidgets([ts, is]);
    } else {
      setWidgets([is]);
    }

    setSystemAlerts(sa);
  };

  React.useEffect(() => {
    if ((!graphWidgets || graphWidgets[0] === undefined) && user) {
      fetchWidgets();
      window.clearInterval(interval.current);
      interval.current = setInterval(fetchWidgets, 120000);
    }
  }, [user]);

  React.useEffect(() => {
    const fetchISTA = async () => {
      const ista = await fetchInfectionSafeTrendAnalysis();

      setInfectionSafeTrendAnalysis(ista);

      setGraphWidgets([ista]);
    };

    fetchISTA();
  }, [infectionSafeTrendAnalysisLookbackDays]);

  return (
    <Box style={containerStyle}>
      <FormattedDate />

      <Typography
        variant="h5"
        component="div"
        sx={{ ...titleStyle, mb: "20px" }}
      >
        Live Overview
      </Typography>

      {widgets && graphWidgets && systemAlerts ? (
        <>
          <div style={mainContainerNavStyle}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={0} style={{ marginLeft: 0 }}>
                  {widgets &&
                    widgets.map((widget, widgetIndex) => {
                      const valueColor =
                        widgetIndex % 2 === 0 ? blueText : cyanText;

                      return (
                        <DashboardWidget
                          cols={widgetColWidths[widget.widgetName]}
                          valueColor={valueColor}
                          widget={widget}
                        />
                      );
                    })}
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container spacing={0} style={{ marginLeft: 0 }}>
                  {graphWidgets &&
                    graphWidgets.map((widget) => {
                      return <ChartWidget widget={widget} />;
                    })}
                </Grid>
              </Grid>
            </Grid>
          </div>
          <Typography
            component="div"
            sx={{ ...titleStyle, mb: "20px", ml: "23px", fontSize: "21px" }}
          >
            System alerts
          </Typography>
          <div style={mainContainerNavStyle}>
            {systemAlerts ? (
              <WearableAlertWidget widget={systemAlerts} />
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <>
          <Grid container>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "100px",
                }}
              >
                <CircularProgress />
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}

export default HomeTemplate;
