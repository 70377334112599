const tabContentStyle = {
  height: "100%",
  border: "1px solid #4D6879",
};

const topDateStyle = {
  color: "#8f9ab5",
  fontSize: 14,
  marginBottom: "1px",
  fontFamily: "Open Sans",
  fontWeight: 400,
  paddingTop: "20px",
  paddingLeft: "0px",
};

const inputStyle = {
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  borderRadius: "8px",
  fontSize: "14px",
  fontWeight: 600,
  padding: "13px 15px 17px 13px",
  width: "82%",
  // borderWidth: "0px",
  borderRight: "12px solid white",
  borderLeft: "0px solid white",
  borderTop: "0px solid white",
  borderBottom: "0px solid white",
  // borderRightWidth: "15px",
  color: "#283555",
};

const autocompleteInputStyle = {
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  borderRadius: "8px",
  fontSize: "14px",
  fontWeight: 600,
  padding: "13px 15px 17px 13px",
  // borderWidth: "0px",
  borderRight: "0px solid white",
  borderLeft: "0px solid white",
  borderTop: "0px solid white",
  borderBottom: "0px solid white",
  // borderRightWidth: "15px",
  color: "#283555",
  width: "82%",
  maxHeight: "57px",
  padding: "0px",
};

const disabledInputStyle = {
  background: "#EAEDEF 0% 0% no-repeat padding-box",
  borderRadius: "8px",
  fontSize: "14px",
  fontWeight: 600,
  padding: "13px 15px 17px 13px",
  width: "82%",
  borderRight: "12px solid #EAEDEF",
  borderLeft: "0px solid #EAEDEF",
  borderTop: "0px solid #EAEDEF",
  borderBottom: "0px solid #EAEDEF",
  color: "#283555",
};

const goBackStyle = topDateStyle;

const titleStyle = {
  color: "#283555",
  fontSize: 23,
  fontWeight: 700,
  fontFamily: "Open Sans",
};

const mainContainerNavStyle = {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: 20,
};

const tableLinkStyle = {
  mb: 1.5,
  mt: "2px",
  color: "#01C1D3",
  fontWeight: 600,
  fontSize: 12,
  fontFamily: "Open Sans",
};

const MuiSelectStyle = {
  ...inputStyle,
  padding: 0,
};

const leftCardStyle = {
  borderRadius: "8px",
  boxShadow: "none",
};

const scrollableTableStyle = {
  borderRadius: "8px",
  boxShadow: "none",
  minWidth: "275px",
  overflowX: "auto",
};

const replayLeftCardStyle = {
  backgroundColor: "#EFF4FF",
  minWidth: "90%",
};

const replayRightCardStyle = {
  backgroundColor: "#EFF4FF",
  width: "100%",
};

const buttonGroupContainerStyle = {
  display: "inline-flex",
  width: "100%",
};

const buttonStyle = {
  padding: "9px 18px 9px 18px",
  // margin: "0px 11px 0px 11px",
  whiteSpace: "no-wrap",
  height: 38,
  textDecoration: "underline",
  border: 0,
  color: "#FFF",
  display: "inline-block",
  borderRadius: "500px",
  cursor: "pointer",
  fontSize: "13px",
  fontWeight: "bold",
};

const navyBg = {
  background: "#2b3552",
};

const mintBg = {
  background: "#EAFEE0",
};

const mintText = {
  color: "#6ED13E",
};

const yellowBg = {
  background: "#FEFDE0",
};

const statusYellowGlow = {
  borderColor: "#FEFDE0",
};

const yellowText = {
  color: "#D0A002",
};

const lightRedBg = {
  background: "#FFE8E8",
};

const buttonIconStyle = {
  fontSize: 20,
  marginLeft: "-9px",
  marginRight: "6px",
};

const textLinkStyle = {
  background: "none",
  border: "none",
};

const redBg = {
  background: "#F52929",
};

const greenBg = {
  background: "#84D95B",
};

const statusYellowBg = {
  background: "#fff600",
};

const redGlow = {
  borderColor: "#FFE8E8",
};

const greenGlow = {
  borderColor: "#EAFEE0",
};

const redText = {
  color: "#F62A2A",
};

const lightGrayBg = {
  background: "#7D8BB7",
};

const lightGrayBgDisabled = {
  background: "#c9d0e7",
};

const cyanBg = {
  background: "#57BEC8",
};

const cyanText = {
  color: "#01C1D3",
};

const blueText = {
  color: "#1763F7",
};

const disabledText = {
  color: "#8F9AB5",
};

const containerStyle = {
  width: "90%",
  margin: "30px auto 0",
  paddingBottom: "30px",
};

const dashboardWidgetStyle = {
  height: "100%",
  boxShadow: "none",
  borderRadius: "14px",
  width: "100%",
  flexDirection: "column",
  display: "inline-flex",
  borderRadius: "14px",
};

const dashboardWidgetTitleStyle = {
  fontSize: "20px",
  fontWeight: 700,
  color: "#283555",
  fontFamily: "Open Sans",
  padding: "30px 23px 42px 23px",
};

const dashboardWidgetContentStyle = {
  padding: "0px 23px 0px 23px",
  display: "inline-flex",
  width: "100%",
  height: "100%",
  alignItems: "center",
};

const chartWidgetTitleStyle = {
  fontSize: "17px",
  padding: "22px 23px 22px 23px",
  height: "100%",
};

const chartWidgetColumnStyle = {
  justifyContent: "center",
  display: "inline-flex",
  width: "100%",
  height: "100%",
  alignItems: "center",
  // padding: "22px 0px 42px 0px",
};

const chartWidgetStatusStyle = {
  justifyContent: "flex-start",
};

const alertWidgetContentContainerStyle = {
  padding: "0px",
  display: "inline-flex",
  width: "100%",
};

const dashboardWidgetHrStyle = {
  margin: "20px 0px 20px 0px",
  display: "block",
};

const alertWidgetHrStyle = {
  margin: "0px 20px 0px 0px",
  display: "block",
};

const dashboardWidgetValueStyle = {
  fontSize: 21,
  fontWeight: 700,
};

const alertWidgetValueStyle = {
  fontSize: "33px",
  fontWeight: 700,
};

const alertWidgetLabelStyle = {
  fontSize: "17px",
  fontWeight: 700,
  color: "#283555",
};

const alertWidgetStatusStyle = {
  display: "inline-flex",
  justifyContent: "flex-end",
  width: "100%",
};

const alertWidgetStyle = {
  fontSize: "16px",
  color: "#96A0B9",
  flexDirection: "row",
  display: "inline-flex",
  paddingBottom: "0px",
};

const statusPillStyle = {
  minWidth: "91px",
  minHeight: "21px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 10,
  fontWeight: 700,
  borderRadius: 10,
  textTransform: "capitalize",
};

const dashboardTableColumnStyle = {
  justifyContent: "center",
  display: "inline-flex",
  width: "100%",
  alignItems: "center",
};

const tableCaretDownIconStyle = {
  color: "#919191",
};

const downloadContainerStyle = {
  display: "inline-flex",
  alignItems: "center",
  position: "relative",
  // marginLeft: "25px",
  marginLeft: "20px",
};

const widgetBodyStyle = {
  paddingBottom: "30px",
};

const glowingDotStyle = {
  width: "25px",
  height: "25px",
  borderRadius: "100%",
  borderWidth: "5px",
  borderStyle: "solid",
};

const alertWidgetContentStyle = {
  padding: "21px 24px 21px 24px",
  width: "100%",
};

const buttonJustifiedRightStyle = {
  display: "inline-flex",
  justifyContent: "center",
  width: "100%",
};

const locateTeamMemberCardStyle = {
  ...leftCardStyle,
  minWidth: "inherit",
  width: "90%",
  minHeight: "465px",
  display: "inline-flex",
};

const investigateEventCardStyle = {
  ...leftCardStyle,
  minWidth: "inherit",
  width: "100%",
  minHeight: "465px",
  background: "transparent",
};

const inputLabelStyle = {
  color: "#283555",
  fontSize: "12px",
  marginBottom: "4px",
  margin: "5px",
};

const orStyle = {
  color: "#8F9AB5",
  fontSize: 15,
  fontWeight: 700,
  height: "100%",
  alignItems: "center",
  marginLeft: 5,
  display: "inline-flex",
};

const chartWidgetBodyStyle = {
  padding: "0px 24px",
};

const datePickerStyle = {
  maxWidth: "80%",
  backgroundColor: "white",
  borderRadius: "8px",
  "& fieldset": {
    border: "none",
    boxShadow: "none",
    margin: 0,
  },
  "& .MuiSvgIcon-root": { color: "#8F9AB5" },
  "& input": {
    color: "#A2B0D1",
    fontSize: "14px",
    fontFamily: "Open Sans",
    fontWeight: 600,
  },
};

const investigateEventInputLabelStyle = {
  marginLeft: "13px",
  fontSize: "12px",
  color: "#283555",
  fontFamily: "Open Sans",
  fontWeight: 400,
  display: "block",
};

const actionTakenStyle = {
  paddingTop: "24px",
  display: "inline-flex",
  alignItems: "center",
};

const formLinkStyle = {
  color: "#283555",
  fontSize: 12,
  fontWeight: 400,
  textDecoration: "underline",
  background: "transparent",
  borderWidth: 0,
  cursor: "pointer",
};

const downloadIconContainerStyle = {
  display: "inline-flex",
  background: "red",
  position: "relative",
  width: 25,
  height: 25,
  padding: 0,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#eaf0ff",
  marginTop: "10px",
  // marginRight: "27px",
};

const downloadIconStyle = {
  color: "#283555",
  fontSize: 16,
  background: "transparent",
  margin: 0,
  padding: 0,
};

const searchNowButtonStyle = {
  padding: "9px 18px 9px 18px",
  margin: "auto",
  height: 38,
  width: "100%",
  display: "block",
  marginTop: "20px",
  borderRadius: "8px",
  textDecoration: "none",
  background: "#01C1D3",
};

const searchNowButtonDisabledStyle = {
  background: "#D2D8E8",
  padding: "9px 18px 9px 18px",
  cursor: "inherit",
  margin: "auto",
  height: 38,
  width: "100%",
  display: "block",
  marginTop: "20px",
  textDecoration: "none",
  borderRadius: "8px",
};

const buttonTextStyle = {
  marginBottom: 1.5,
  color: "white",
  textDecoration: "none",
  fontSize: "12px",
  fontFamily: "Open Sans",
};

const rightCardStyle = {
  borderRadius: "8px",
  boxShadow: "none",
  minWidth: "275px",
  overflowX: "auto",
  maxWidth: "100vw",
};

const tableArrowIconStyle = {
  color: "#919191",
  marginLeft: "2px",
  paddingTop: "2px",
  marginBottom: "-1px",
};

const viewReplayButtonStyle = {
  display: "flex",
  alignItems: "top",
  flexWrap: "wrap",
  background: "transparent",
  border: 0,
  cursor: "pointer",
};

const tableReplayStyle = {
  mb: 1.5,
  color: "#1763F7",
  textDecoration: "underline",
  marginLeft: "8px",
  fontWeight: 500,
  fontSize: 12,
  fontFamily: "Open Sans",
};

const tableCaretRightIconStyle = {
  color: "#919191",
  fontSize: 12,
  marginLeft: "9px",
  marginTop: "4px",
};

const clickableText = {
  background: "inherit",
  border: "none",
  cursor: "pointer",
};

const gdprModalStyle = {
  textDecoration: "none",
  fontSize: "14px",
  fontWeight: 600,
  fontFamily: "Open Sans",
};

const scrollableContent = {
  maxHeight: "265px",
  flex: 1,
  overflow: "auto",
  marginBottom: "32px",
};

const scrollableContentModal = { overflow: "auto", height: "100%" };

const addIconStyle = {
  marginTop: "2px",
};

const searchWrapperButtonStyle = {
  padding: 0,
  marginTop: "3px",
};

const playIconStyle = {
  marginLeft: "3px",
};

const activeIconStyle = {
  color: "white",
  backgroundColor: "navy !important",
};

const fastForwardIconStyle = {
  marginLeft: "1px",
  marginRight: "-2px",
};

const fastRewindIconStyle = {
  marginLeft: "-3px",
};

const MenuItemStyle = { margin: 0, padding: 0 };

const MenuItemInnerStyle = { padding: "0px 7px" };

const MenuItemDisabledStyle = {
  width: "100%",
  background: "none",
  border: "none",
  cursor: "pointer",
  textAlign: "left",
  fontSize: "16px",
  color: "#ccc",
};

const zoomIconContainerStyle = {
  position: "relative",
  width: 50,
  height: 50,
  padding: 10,
  marginTop: 25,
  marginRight: 27,
  display: "block",
  border: "none ",
  cursor: "pointer",
};

const zoomPositionStyle = {
  position: "absolute",
  right: "0px",
  bottom: "19px",
  display: "block",
  zIndex: 5,
};

const SelectNoneOptionStyle = { padding: "0px 7px" };

const downloadLinkText = {
  marginBottom: 0,
  color: "#283555",
  textDecoration: "underline",
  fontWeight: 600,
  display: "inline-block",
  fontSize: 12,
};

const riskLevelBoxStyle = {
  bgcolor: "white",
  width: "100%",
  border: "1px solid #ccc",
};

const encounterSelectStyle = {
  bgcolor: "white",
  margin: "0px 30px",
  alignContent: "center",
  border: "1px solid #ccc",
};

const contactSelectStyle = {
  bgcolor: "white",
  margin: "0px",
  alignContent: "center",
  border: "1px solid #ccc",
  padding: "0px",
};

export {
  zoomIconContainerStyle,
  encounterSelectStyle,
  contactSelectStyle,
  riskLevelBoxStyle,
  downloadLinkText,
  downloadIconContainerStyle,
  downloadIconStyle,
  zoomPositionStyle,
  fastRewindIconStyle,
  fastForwardIconStyle,
  playIconStyle,
  searchWrapperButtonStyle,
  gdprModalStyle,
  searchNowButtonDisabledStyle,
  searchNowButtonStyle,
  buttonTextStyle,
  navyBg,
  lightGrayBg,
  lightGrayBgDisabled,
  tabContentStyle,
  dashboardWidgetHrStyle,
  topDateStyle,
  titleStyle,
  cyanBg,
  mainContainerNavStyle,
  tableLinkStyle,
  leftCardStyle,
  replayLeftCardStyle,
  replayRightCardStyle,
  buttonStyle,
  goBackStyle,
  containerStyle,
  dashboardWidgetStyle,
  dashboardWidgetContentStyle,
  dashboardWidgetTitleStyle,
  dashboardWidgetValueStyle,
  chartWidgetTitleStyle,
  chartWidgetColumnStyle,
  chartWidgetStatusStyle,
  statusPillStyle,
  mintBg,
  mintText,
  yellowBg,
  statusYellowBg,
  statusYellowGlow,
  yellowText,
  lightRedBg,
  redText,
  dashboardTableColumnStyle,
  tableCaretDownIconStyle,
  widgetBodyStyle,
  cyanText,
  greenBg,
  redBg,
  greenGlow,
  redGlow,
  glowingDotStyle,
  blueText,
  disabledText,
  autocompleteInputStyle,
  alertWidgetValueStyle,
  alertWidgetLabelStyle,
  alertWidgetStyle,
  alertWidgetStatusStyle,
  alertWidgetHrStyle,
  alertWidgetContentStyle,
  alertWidgetContentContainerStyle,
  buttonGroupContainerStyle,
  buttonJustifiedRightStyle,
  downloadContainerStyle,
  locateTeamMemberCardStyle,
  investigateEventCardStyle,
  inputLabelStyle,
  inputStyle,
  disabledInputStyle,
  chartWidgetBodyStyle,
  datePickerStyle,
  investigateEventInputLabelStyle,
  orStyle,
  formLinkStyle,
  actionTakenStyle,
  rightCardStyle,
  tableArrowIconStyle,
  viewReplayButtonStyle,
  tableReplayStyle,
  tableCaretRightIconStyle,
  clickableText,
  scrollableContent,
  scrollableContentModal,
  buttonIconStyle,
  textLinkStyle,
  addIconStyle,
  scrollableTableStyle,
  activeIconStyle,
  MuiSelectStyle,
  SelectNoneOptionStyle,
  MenuItemStyle,
  MenuItemInnerStyle,
  MenuItemDisabledStyle,
};
