import React from "react";

function HeaderBellIcon({ style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      style={style ? style : {}}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_16426"
            data-name="Rectangle 16426"
            width="17.813"
            height="20.418"
            fill="#1763f7"
          />
        </clipPath>
      </defs>
      <g
        id="Group_17138"
        data-name="Group 17138"
        transform="translate(-1063 -16)"
      >
        <circle
          id="Ellipse_107"
          data-name="Ellipse 107"
          cx="20"
          cy="20"
          r="20"
          transform="translate(1063 16)"
          fill="#eaf0ff"
        />
        <g
          id="Group_16909"
          data-name="Group 16909"
          transform="translate(1074.094 26)"
        >
          <g
            id="Group_16202"
            data-name="Group 16202"
            clip-path="url(#clip-path)"
          >
            <path
              id="Path_41243"
              data-name="Path 41243"
              d="M6.269,17.778H1.026a1.445,1.445,0,0,1-.65-.128.558.558,0,0,1-.352-.714,3.463,3.463,0,0,1,.455-1.121c.27-.436.539-.874.834-1.293a5.656,5.656,0,0,0,.565-.957A9.3,9.3,0,0,0,2.5,11.419a19.891,19.891,0,0,0,.252-3.2A6.074,6.074,0,0,1,6.117,2.674,5.457,5.457,0,0,1,7.832,2.1c.085-.015.116-.044.114-.134-.006-.334-.005-.669,0-1A.961.961,0,0,1,9.858.821a1.318,1.318,0,0,1,.01.2c0,.316,0,.632,0,.948,0,.071.017.1.094.116A6.083,6.083,0,0,1,14.04,4.724a6.2,6.2,0,0,1,1.052,3.7,13.037,13.037,0,0,0,.761,4.873,5.779,5.779,0,0,0,.638,1.209c.34.5.658,1.017.968,1.538a2.841,2.841,0,0,1,.339.94.527.527,0,0,1-.278.62,1.327,1.327,0,0,1-.706.172h-3c-.71,0-1.42,0-2.13,0-.092,0-.118.028-.122.119a2.642,2.642,0,0,1-5.268.15c-.008-.084-.015-.169-.024-.266"
              transform="translate(0 0.001)"
              fill="#1763f7"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default HeaderBellIcon;
