import React from "react";

function InfectionSafeIcon({ style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      style={style ? style : {}}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_16577"
            data-name="Rectangle 16577"
            width="12.203"
            height="12.199"
            fill="#283555"
          />
        </clipPath>
      </defs>
      <g
        id="Group_17131"
        data-name="Group 17131"
        transform="translate(-365 -86)"
      >
        <circle
          id="Ellipse_115"
          data-name="Ellipse 115"
          cx="14"
          cy="14"
          r="14"
          transform="translate(365 86)"
          fill="#eaf0ff"
        />
        <g
          id="Group_16957"
          data-name="Group 16957"
          transform="translate(373 93)"
        >
          <g
            id="Group_16956"
            data-name="Group 16956"
            clip-path="url(#clip-path)"
          >
            <path
              id="Path_41337"
              data-name="Path 41337"
              d="M12.2,6.106A6.1,6.1,0,1,1,6.11,0,6.084,6.084,0,0,1,12.2,6.106M6.1,1.946c-.919-.083-1.143.368-1.085,1.134.093,1.231.157,2.463.239,3.695a.741.741,0,0,0,.627.7.846.846,0,0,0,1.1-.866q.108-1.833.228-3.666c.045-.685-.24-1-.931-1H6.1m0,8.316a.975.975,0,1,0-.968-.974.986.986,0,0,0,.968.974"
              transform="translate(0)"
              fill="#283555"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default InfectionSafeIcon;
