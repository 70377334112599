import React from "react";
import { Page, Image, Text, View, Document } from "@react-pdf/renderer";
import moment from "moment";

const ReportTemplate = (
  pdfHeaderImage,
  reportGeneratedBy,
  reportGeneratedByEmail,
  body,
  title,
  reportId
) => {
  return (
    <Document>
      <Page size="A4" style={{ padding: "20px" }}>
        <View style={{ display: "block", margin: "10px 10px 0px 10px" }}>
          <Image
            style={{
              width: "25%",
              height: "auto",
            }}
            src={`${pdfHeaderImage}?cachebuster=${new Date().getTime()}`}
          />
        </View>
        <View style={{ display: "block", margin: "10px 10px 10px 10px" }}>
          <Text>{title}</Text>
        </View>
        <View style={{ display: "block", margin: "0px 10px 10px 10px" }}>
          <Text
            style={{
              fontSize: 10,
              color: "#8f9ab5",
              display: "block",
              margin: "2px 10px 2px 0px",
            }}
          >
            Report generated by {reportGeneratedBy}
          </Text>
          <Text
            style={{
              fontSize: 10,
              color: "#8f9ab5",
              display: "block",
              margin: "2px 10px 2px 0px",
            }}
          >
            Email: {reportGeneratedByEmail}
          </Text>
          <Text
            style={{
              fontSize: 10,
              color: "#8f9ab5",
              display: "block",
              margin: "2px 10px 2px 0px",
            }}
          >
            Report ID: {reportId ? reportId : "N/A"}
          </Text>
          <Text
            style={{
              fontSize: 10,
              color: "#8f9ab5",
              display: "block",
              margin: "2px 10px 2px 0px",
            }}
          >
            {moment(new Date()).format("lll")}
          </Text>
        </View>

        {body}
      </Page>
    </Document>
  );
};

export default ReportTemplate;
