import React from "react";

function HeaderQuestionMarkIcon({ style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      style={style ? style : {}}
    >
      <g
        id="Group_17136"
        data-name="Group 17136"
        transform="translate(-963 -16)"
      >
        <circle
          id="Ellipse_150"
          data-name="Ellipse 150"
          cx="20"
          cy="20"
          r="20"
          transform="translate(963 16)"
          fill="#eaf0ff"
        />
        <path
          id="Icon_ionic-ios-help-circle"
          data-name="Icon ionic-ios-help-circle"
          d="M14.757,3.375A11.382,11.382,0,1,0,26.139,14.757,11.38,11.38,0,0,0,14.757,3.375ZM14.522,20.01A1.128,1.128,0,1,1,15.7,18.883,1.143,1.143,0,0,1,14.522,20.01Zm2.2-5.3c-.952.553-1.275.958-1.275,1.658V16.8h-1.9l-.016-.471a2.278,2.278,0,0,1,1.291-2.408c.925-.553,1.313-.9,1.313-1.581a1.328,1.328,0,0,0-1.472-1.176A1.384,1.384,0,0,0,13.2,12.508H11.255C11.293,10.746,12.6,9.5,14.8,9.5c2.052,0,3.464,1.138,3.464,2.774A2.678,2.678,0,0,1,16.721,14.708Z"
          transform="translate(968.625 20.111)"
          fill="#1763f7"
        />
      </g>
    </svg>
  );
}

export default HeaderQuestionMarkIcon;
