import React from "react";

function PersonIcon({ style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 40 39"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_16643"
            data-name="Rectangle 16643"
            width="14.787"
            height="17.734"
            fill={style && style.color ? style.color : "#1876D1"}
          />
        </clipPath>
      </defs>
      <g id="Group_17114" data-name="Group 17114" transform="translate(0.449)">
        <ellipse
          id="Ellipse_116"
          data-name="Ellipse 116"
          cx="20"
          cy="19.5"
          rx="20"
          ry="19.5"
          transform="translate(-0.449)"
          fill={
            style && style.backgroundColor ? style.backgroundColor : "#e326dc"
          }
        />
        <g
          id="Group_17001"
          data-name="Group 17001"
          transform="translate(12.94 9.82)"
        >
          <g
            id="Group_17000"
            data-name="Group 17000"
            clip-path="url(#clip-path)"
          >
            <path
              id="Path_41371"
              data-name="Path 41371"
              d="M7.407,17.731c1.466,0,2.931.01,4.4,0a2.822,2.822,0,0,0,2.925-2.416,8.545,8.545,0,0,0-.826-4.906A3.222,3.222,0,0,0,11.872,8.7a1.874,1.874,0,0,0-1.831.254,3.952,3.952,0,0,1-.58.376,3.749,3.749,0,0,1-3.444.309c-.514-.219-.979-.553-1.482-.8a1.863,1.863,0,0,0-.8-.242A3.147,3.147,0,0,0,.882,10.4,8.44,8.44,0,0,0,0,14.238a5.17,5.17,0,0,0,.1,1.3,2.842,2.842,0,0,0,2.873,2.186c1.477.012,2.954,0,4.431,0M3.016,4.274a4.271,4.271,0,0,0,8.542.008,4.271,4.271,0,1,0-8.542-.008"
              transform="translate(0 0)"
              fill={style && style.color ? style.color : "#1876D1"}
            />
            <path
              id="Path_41372"
              data-name="Path 41372"
              d="M7.408,132.97c-1.477,0-2.954.009-4.431,0A2.842,2.842,0,0,1,.1,130.781a5.17,5.17,0,0,1-.1-1.3,8.44,8.44,0,0,1,.878-3.835,3.147,3.147,0,0,1,2.851-1.81,1.862,1.862,0,0,1,.8.242c.5.249.968.583,1.482.8a3.749,3.749,0,0,0,3.444-.309,3.961,3.961,0,0,0,.58-.376,1.874,1.874,0,0,1,1.831-.254,3.222,3.222,0,0,1,2.031,1.706,8.545,8.545,0,0,1,.826,4.906,2.822,2.822,0,0,1-2.925,2.416c-1.466.012-2.931,0-4.4,0"
              transform="translate(0 -115.239)"
              fill={style && style.color ? style.color : "#1876D1"}
            />
            <path
              id="Path_41373"
              data-name="Path 41373"
              d="M43.543,4.274A4.271,4.271,0,1,1,47.8,8.53a4.225,4.225,0,0,1-4.26-4.256"
              transform="translate(-40.527 0)"
              fill={style && style.color ? style.color : "#1876D1"}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PersonIcon;
