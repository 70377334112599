import React from "react";
import { Text, View, Document } from "@react-pdf/renderer";
import { pdfStyles } from "../helpers/helpers";
import moment from "moment";
import ReportTemplate from "./ReportTemplate";

const ICSReportPdf = (
  reportGeneratedBy,
  reportGeneratedByEmail,
  pdfHeaderImage,
  formattedArray,
  formattedPathogenArray,
  reportId
) => {
  let pdfTable, prevPage;
  let page = 1;

  if (formattedArray && formattedPathogenArray && !pdfTable) {
    pdfTable = {
      column: [
        "Name of case",
        "Status",
        "Onset date/time",
        "Pathogen or disease",
        "Actions",
        "No of contact events",
        "No of contaminated rooms",
      ],
      data: formattedArray.map((infection) => {
        return {
          "Name of case": infection.firstName + " " + infection.lastName,
          Status: infection.status,
          "Onset date/time": moment(new Date(infection.onsetTime)).format(
            "lll"
          ),
          "Pathogen or disease": formattedPathogenArray.find(
            (p) => parseInt(p.id) === parseInt(infection.pathogenId)
          ).pathogenName,
          Actions: infection.actionsString,
          "No of contact events": infection.exposedContacts,
          "No of contaminated rooms": infection.contaminatedRooms,
        };
      }),
    };
  }
  if (pdfTable && reportGeneratedBy && pdfHeaderImage) {
    const body = (
      <View style={{ marginBottom: "200px" }}>
        <View style={pdfStyles.rowView}>
          {pdfTable["column"].map((c) => (
            <Text
              style={
                c === "Onset date/time"
                  ? { width: "150px", paddingLeft: "5px" }
                  : c === "Status"
                  ? { width: "50px", paddingLeft: "5px" }
                  : c === "Name of case"
                  ? { width: `${100 / pdfTable["column"].length}%` }
                  : {
                      width: `${100 / pdfTable["column"].length}%`,
                      paddingLeft: "5px",
                    }
              }
            >
              {c}
            </Text>
          ))}
        </View>
        {pdfTable["data"].map((rowData, index) => {
          if (index === 10 && page === 1) {
            page++;
          } else if (page > 1 && index % 14 === 10) {
            page++;
          }

          let lastRow =
            index === 9 ||
            (page > 1 && index % 14 === 9 && index > 11) ||
            index === pdfTable["data"].length - 1;

          return (
            <>
              <View style={pdfStyles.rowView}>
                {pdfTable["column"].map((c) => (
                  <>
                    <Text
                      style={
                        c === "Onset date/time"
                          ? { width: "150px", paddingLeft: "5px" }
                          : c === "Status"
                          ? { width: "50px", paddingLeft: "5px" }
                          : c === "Name of case"
                          ? { width: `${100 / pdfTable["column"].length}%` }
                          : {
                              width: `${100 / pdfTable["column"].length}%`,
                              paddingLeft: "5px",
                            }
                      }
                    >
                      {rowData[c]}
                    </Text>
                  </>
                ))}
              </View>
              {lastRow ? (
                <View
                  style={{
                    ...pdfStyles.rowView,
                    ...{
                      position: "relative",

                      left: "505px",
                      bottom: "0px",
                      right: "0px",
                      fontSize: "10px",
                      borderTop: "none",
                    },
                  }}
                >
                  <Text
                    render={({ pageNumber, totalPages }) => {
                      if (prevPage !== pageNumber || totalPages === 1) {
                        prevPage = pageNumber;
                        return `Pg ${pageNumber} / ${totalPages}`;
                      } else {
                        return "";
                      }
                    }}
                    fixed
                  />
                </View>
              ) : (
                <View></View>
              )}
            </>
          );
        })}
      </View>
    );

    return ReportTemplate(
      pdfHeaderImage,
      reportGeneratedBy,
      reportGeneratedByEmail,
      body,
      "Infection Control Summary",
      reportId
    );
  } else return <Document></Document>;
};

export default ICSReportPdf;
