import React from "react";

function ReportsIcon({ style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      style={style ? style : {}}
    >
      <g
        id="Group_17135"
        data-name="Group 17135"
        transform="translate(-1078.266 -86)"
      >
        <circle
          id="Ellipse_149"
          data-name="Ellipse 149"
          cx="14"
          cy="14"
          r="14"
          transform="translate(1078.266 86)"
          fill="#eaf0ff"
        />
        <g
          id="Icon_ionic-ios-document"
          data-name="Icon ionic-ios-document"
          transform="translate(1080.953 89.635)"
        >
          <path
            id="Path_41390"
            data-name="Path 41390"
            d="M21.149,6.794h2.622a.143.143,0,0,0,.144-.144h0a.853.853,0,0,0-.308-.66l-2.2-1.833a.925.925,0,0,0-.594-.213h0a.213.213,0,0,0-.213.213V6.247A.547.547,0,0,0,21.149,6.794Z"
            transform="translate(-7.843 -0.004)"
            fill="#283555"
          />
          <path
            id="Path_41391"
            data-name="Path 41391"
            d="M12.009,6.243V3.937H8.235a.925.925,0,0,0-.922.922v9.681a.925.925,0,0,0,.922.922H15.15a.925.925,0,0,0,.922-.922v-7H13.306A1.3,1.3,0,0,1,12.009,6.243Z"
            transform="translate(0 0)"
            fill="#283555"
          />
        </g>
      </g>
    </svg>
  );
}

export default ReportsIcon;
