import React from "react";

function SearchIcon({ style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.118"
      height="15.118"
      viewBox="0 0 15.118 15.118"
      style={style ? style : {}}
    >
      <path
        id="magnifiying-glass_1_"
        data-name="magnifiying-glass (1)"
        d="M14.649,13.835l-3.616-3.616a6.246,6.246,0,1,0-.815.815l3.616,3.616a.576.576,0,1,0,.815-.815Zm-13.5-7.6a5.084,5.084,0,1,1,5.085,5.084A5.09,5.09,0,0,1,1.152,6.237Z"
        transform="translate(0.15 0.15)"
        fill="#8f9ab5"
        stroke="#1e1e1e"
        stroke-width="0.3"
      />
    </svg>
  );
}

export default SearchIcon;
