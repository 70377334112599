import React from "react";

function AddIcon({ style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 15.431 15.431"
      style={style ? style : {}}
    >
      <path
        id="Icon_ionic-ios-add-circle"
        data-name="Icon ionic-ios-add-circle"
        d="M11.09,3.375a7.715,7.715,0,1,0,7.715,7.715A7.714,7.714,0,0,0,11.09,3.375Zm3.357,8.309H11.684v2.763a.594.594,0,0,1-1.187,0V11.684H7.734a.593.593,0,1,1,0-1.187H10.5V7.734a.593.593,0,1,1,1.187,0V10.5h2.763a.593.593,0,0,1,0,1.187Z"
        transform="translate(-3.375 -3.375)"
        fill={style && style.color ? style.color : "#ffffff"}
      />
    </svg>
  );
}

export default AddIcon;
