import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, FormControl, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getAccessToken } from "../helpers/helpers";
import { riskLevelBoxStyle } from "../styles/styles";


export default function CaseStatusSwitcher({ initialStatus: initialStatusValue, caseId }) {

  const { getAccessTokenSilently } = useAuth0();
  const [caseStatusValue, setCaseStatusValue] = useState(initialStatusValue);
  const [editing, setEditing] = useState(false);
  const [caseStatusDropdownOpen, setCaseStatusDropdownOpen] = useState(false);

  const editRef = React.useRef(null);

  const handleEditButtonClick = () => {
    setEditing(true);
  };

  const statusOptions = [
    { label: 'Open', value: 'open' },
    { label: 'Closed', value: 'closed' },
    { label: 'Withdrawn', value: 'rejected' }
  ];

  const currentStatusOption = statusOptions.find(option => option.value === caseStatusValue.toLowerCase());

  const handleStatusChange = async (e) => {
    console.log('Changing case status...');

    const newStatusValue = e.target.value;
    const accessToken = await getAccessToken(getAccessTokenSilently);

    fetch(`${process.env.REACT_APP_API_URL}/cases/${caseId}/status/${newStatusValue}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
    })
      .then(async response => {

        if (response.ok) {

          setCaseStatusValue(newStatusValue);
          console.log('Case status changed');

        } else {
          // Handle error case
          console.error('Failed to change case status');
        }
      })
      .catch(error => {
        // Handle network error
        console.error('Error changing case status:', error);
      })
      .finally(() => {
        setEditing(false);
      });

  };

  useEffect(() => {

    const handleClickOutside = (event) => {

      if (
        !caseStatusDropdownOpen &&
        editRef.current &&
        !editRef.current.contains(event.target)
      ) {
        setEditing(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };

  }, [caseStatusDropdownOpen]);
 

  return (
    <>

      {editing ? (

        <Box sx={riskLevelBoxStyle} ref={editRef}>
          <FormControl fullWidth>
            <Select
              id="case-status-select"
              value={caseStatusValue.toLowerCase()}
              onOpen={() => setCaseStatusDropdownOpen(true)}
              onClose={() => {
                setCaseStatusDropdownOpen(false);
                setEditing(false);
              }}
              onChange={handleStatusChange}
            >
              {statusOptions.map((statusOption) => (
                <MenuItem key={statusOption.value} value={statusOption.value}>
                  {statusOption.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

      ) : (

        <Button
          variant="outlined"
          color="secondary"
          onClick={handleEditButtonClick}
        >
          {currentStatusOption.label}
        </Button>

      )}

    </>
  )
}
