import React from "react";
import { Text, View, Document } from "@react-pdf/renderer";
import { pdfStyles } from "../helpers/helpers";
import moment from "moment";
import ReportTemplate from "./ReportTemplate";

const ICCReportPdf = (
  reportGeneratedBy,
  reportGeneratedByEmail,
  pdfHeaderImage,
  infectedPerson,
  formattedExposuresArray,
  actionsString,
  reportId
) => {
  let infectedPersonPdfTable, exposuresPdfTable;

  if (
    infectedPerson &&
    formattedExposuresArray &&
    actionsString &&
    !infectedPersonPdfTable
  ) {
    infectedPersonPdfTable = {
      column: [
        "Name of case",
        "Role",
        "Date and time of exposure",
        "Pathogen/disease",
        "Actions",
        "No of contact events",
        "No of contaminated rooms",
      ],
      data: [
        {
          "Name of case":
            infectedPerson.firstName + " " + infectedPerson.lastName,
          Role: infectedPerson.status,
          "Date and time of exposure": moment(
            new Date(infectedPerson.onsetTime)
          ).format("lll"),
          "Pathogen/disease": infectedPerson.pathogenName,
          Actions: actionsString,
          "No of contact events": formattedExposuresArray.length,
          // TODO: updated this with new number later, after exposures have been loaded ^
          "No of contaminated rooms": infectedPerson.contaminatedRooms,
        },
      ],
    };
  }

  if (
    infectedPerson &&
    formattedExposuresArray &&
    !exposuresPdfTable &&
    formattedExposuresArray
  ) {
    if (formattedExposuresArray.length > 0) {
      exposuresPdfTable = {
        column: [
          "List of all contacts",
          "Role",
          "Duration of contact",
          "Proximity of contact",
          "Actions for contact",
        ],
        data: formattedExposuresArray.map((exposure) => ({
          "List of all contacts":
            exposure.firstName && exposure.lastName
              ? exposure.firstName + " " + exposure.lastName
              : `Unassigned tag (${exposure.exposedPersonSerial})`,
          Role: exposure.status,
          "Duration of contact": exposure.durationInMinutes,
          "Proximity of contact": exposure.avgDistanceInCm,
          "Actions for contact": exposure.actions,
        })),
      };
    }
  }

  if (infectedPersonPdfTable && exposuresPdfTable && pdfHeaderImage) {
    let prevPage = 0;
    let page = 1,
      relativeRowIndex = 1;

    const body = (
      <View>
        <View style={pdfStyles.blockView}>
          <Text style={{ fontSize: "15px", margin: "10px" }}>Index case</Text>
          <View style={pdfStyles.rowView}>
            {infectedPersonPdfTable["column"].map((c) => (
              <Text
                style={
                  c === "Pathogen/disease" || c === "Date and time of exposure"
                    ? { width: "150px", paddingLeft: "5px" }
                    : c === "Name of case"
                    ? { width: "90px" }
                    : c === "Role"
                    ? { width: "40px", paddingLeft: "5px" }
                    : {
                        width: `${
                          100 / infectedPersonPdfTable["column"].length
                        }%`,
                        paddingLeft: "5px",
                      }
                }
              >
                {c}
              </Text>
            ))}
          </View>
          {infectedPersonPdfTable["data"].map((rowData) => (
            <View style={pdfStyles.rowView}>
              {infectedPersonPdfTable["column"].map((c) => (
                <Text
                  style={
                    c === "Pathogen/disease" ||
                    c === "Date and time of exposure"
                      ? { width: "150px", paddingLeft: "5px" }
                      : c === "Name of case"
                      ? { width: "90px" }
                      : c === "Role"
                      ? { width: "40px", paddingLeft: "5px" }
                      : {
                          width: `${
                            100 / infectedPersonPdfTable["column"].length
                          }%`,
                          paddingLeft: "5px",
                        }
                  }
                >
                  {rowData[c]}
                </Text>
              ))}
            </View>
          ))}
        </View>
        <View style={pdfStyles.blockView}>
          <Text style={{ fontSize: "15px", margin: "10px" }}>
            List of contacts
          </Text>

          <View style={pdfStyles.rowView}>
            {exposuresPdfTable["column"].map((c) => (
              <Text
                style={
                  c === "Pathogen/disease"
                    ? { width: "150px" }
                    : c === "Date and time of exposure"
                    ? { width: "150px" }
                    : {
                        width: `${100 / exposuresPdfTable["column"].length}%`,
                      }
                }
              >
                {c}
              </Text>
            ))}
          </View>
          {exposuresPdfTable["data"].map((rowData, index) => {
            if (index === 7 && page === 1) {
              page++;
            } else if (page > 1 && index % 14 === 7) {
              page++;
            }

            let lastRow =
              index === 6 ||
              (page > 1 && index % 14 === 6 && index > 8) ||
              index === exposuresPdfTable["data"].length - 1;
            return (
              <>
                <View style={pdfStyles.rowView}>
                  {exposuresPdfTable["column"].map((c) => (
                    <Text
                      style={
                        c === "Pathogen/disease"
                          ? { width: "150px" }
                          : c === "Date and time of exposure"
                          ? { width: "150px" }
                          : {
                              width: `${
                                100 / exposuresPdfTable["column"].length
                              }%`,
                            }
                      }
                    >
                      {rowData[c]}
                    </Text>
                  ))}
                </View>
                {lastRow ? (
                  <View
                    style={
                      page === 1
                        ? {
                            ...pdfStyles.rowView,
                            ...{
                              position: "relative",
                              maxHeight: "35px",
                              left: "505px",
                              bottom: "0px",
                              right: "0px",
                              fontSize: "10px",
                              borderTop: "none",
                            },
                          }
                        : {
                            ...pdfStyles.rowView,
                            ...{
                              position: "relative",
                              maxHeight: "60px",
                              left: "505px",
                              bottom: "0px",
                              right: "0px",
                              fontSize: "10px",
                              borderTop: "none",
                            },
                          }
                    }
                  >
                    <Text
                      render={({ pageNumber, totalPages }) => {
                        if (prevPage !== pageNumber || totalPages === 1) {
                          prevPage = pageNumber;
                          return `Pg ${pageNumber} / ${totalPages}`;
                        } else {
                          return "";
                        }
                      }}
                      fixed
                    />
                  </View>
                ) : (
                  <View></View>
                )}
              </>
            );
          })}
        </View>
      </View>
    );

    return ReportTemplate(
      pdfHeaderImage,
      reportGeneratedBy,
      reportGeneratedByEmail,
      body,
      `Infection Control Consequences`,
      reportId
    );
  } else return <Document></Document>;
};

export default ICCReportPdf;
