import React from "react";
import { Paper, Grid, Typography, Box, Select, MenuItem } from "@mui/material";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import {
  dashboardWidgetStyle,
  dashboardWidgetTitleStyle,
  mintBg,
  yellowBg,
  yellowText,
  lightRedBg,
  redText,
  statusPillStyle,
  mintText,
  chartWidgetTitleStyle,
  chartWidgetColumnStyle,
  chartWidgetStatusStyle,
  chartWidgetBodyStyle,
  widgetBodyStyle,
} from "../styles/styles";
import { Line } from "react-chartjs-2";
import moment from "moment";

function ChartWidget({ widget }) {
  const [filterValue, setFilterValue] = React.useState(widget.lookbackDays);
  const filter = widget.data.filter;
  const status =
    widget.data.filterData &&
    widget.data.filterData[filter] &&
    widget.data.filterData[filter].status;
  const [formattedDates, setFormattedDates] = React.useState(
    widget.data.chartDates.map((date, index) => {
      return moment(date).format("DD/MM");
    })
  );
  const [datasets, setDatasets] = React.useState(
    widget.data.chartData.map((_item, index) => {
      return {
        label: widget.data.chartLabels[index],
        data: widget.data.chartData[index],
        // borderDash: [4, 3],
        borderColor: widget.data.chartColors[index],
        backgroundColor: widget.data.chartColors[index],
        borderWidth: 2,
        pointRadius: 5, // Hide data points
      };
    })
  );

  React.useEffect(() => {
    setFormattedDates(
      widget.data.chartDates.map((date, index) => {
        return moment(date).format("DD/MM");
      })
    );
  }, [widget.data.chartDates]);

  React.useEffect(() => {
    setDatasets(
      widget.data.chartData.map((_item, index) => {
        return {
          label: widget.data.chartLabels[index],
          data: widget.data.chartData[index],
          borderColor: widget.data.chartColors[index],
          backgroundColor: widget.data.chartColors[index],
          borderWidth: 2,
          pointRadius: 5, // Hide data points
        };
      })
    );
  }, [widget.data.chartData]);

  const handleOnChange = (evt) => {
    if (widget.onChange) {
      try {
        widget.onChange(evt.target.value);
        setFilterValue(evt.target.value);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const SimpleLineChart = (props) => {
    const data = {
      labels: formattedDates,
      datasets,
    };

    // Customize chart options to hide axis and labels
    const options = {
      responsive: true, // Enable responsive sizing
      aspectRatio: 2.4, // Set the aspect ratio (width-to-height) to 2:1

      plugins: {
        legend: {
          position: "bottom",
          labels: {
            boxHeight: 1,
          },
        },
      },
    };

    return (
      <div>
        <Line data={data} options={options} {...props} />
      </div>
    );
  };

  return (
    <Grid
      item
      xs={12}
      md={12}
      sx={{
        marginBottom: "14px",
      }}
    >
      <Paper
        style={{
          ...dashboardWidgetStyle,
          height: "auto",
          minHeight: widget.height,
        }}
      >
        <div
          className="widget-body"
          style={{
            ...widgetBodyStyle,
            ...chartWidgetBodyStyle,
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{ ...dashboardWidgetTitleStyle, ...chartWidgetTitleStyle }}
                variant="h4"
                component="h4"
              >
                {widget.widgetName}
              </Typography>{" "}
            </Grid>
            <Grid item xs={12} md={3}>
              <div
                style={{ ...chartWidgetColumnStyle, ...chartWidgetStatusStyle }}
              >
                <div
                  style={{
                    ...statusPillStyle,
                    ...(status === "improving" && mintBg),
                    ...(status === "improving" && mintText),
                    ...(status === "same" && yellowBg),
                    ...(status === "same" && yellowText),
                    ...(status === "worse" && lightRedBg),
                    ...(status === "worse" && redText),
                  }}
                >
                  {status}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div style={chartWidgetColumnStyle}>
                <Select
                  sx={{
                    background: "#F7FAFF",
                    borderRadius: 24,
                    height: "30px",
                  }}
                  value={filterValue}
                  onChange={handleOnChange}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  <MenuItem value={1}>Last 24 hours</MenuItem>
                  <MenuItem value={7}>Last 7 days</MenuItem>
                  <MenuItem value={30}>Last 30 days</MenuItem>
                </Select>
              </div>
            </Grid>
            <Grid item xs={12}>
              <SimpleLineChart
                style={{
                  width: "95%",
                  display: "block",
                  margin: "auto",
                }}
              />
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Grid>
  );
}

export default ChartWidget;
