import React from "react";
import { Text, View, Document } from "@react-pdf/renderer";
import { pdfStyles } from "../helpers/helpers";
import moment from "moment";
import ReportTemplate from "./ReportTemplate";

const MapReplayPdf = (
  reportGeneratedBy,
  reportGeneratedByEmail,
  pdfHeaderImage,
  infectedPerson,
  formattedExposuresArray,
  exposure,
  actionsString,
  reportId
) => {
  let infectedPersonPdfTable, currentExposurePdfTable;

  if (infectedPerson && exposure && actionsString && !infectedPersonPdfTable) {
    infectedPersonPdfTable = {
      column: [
        "Name of case",
        "Role",
        "Date and time of exposure",
        "Pathogen/disease",
        "Actions",
        "No of contact events",
        "No of contaminated rooms",
      ],
      data: [
        {
          "Name of case":
            infectedPerson.firstName + " " + infectedPerson.lastName,
          Role: infectedPerson.status,
          "Date and time of exposure": moment(
            new Date(infectedPerson.onsetTime)
          ).format("lll"),
          "Pathogen/disease": infectedPerson.pathogenName,
          Actions: actionsString,
          "No of contact events": formattedExposuresArray.length,
          // TODO: updated this with new number later, after exposures have been loaded ^
          "No of contaminated rooms": infectedPerson.contaminatedRooms,
        },
      ],
    };
  }

  if (infectedPerson && exposure && !currentExposurePdfTable) {
    currentExposurePdfTable = {
      column: [
        "Name",
        "Role",
        "Date and time of exposure",
        "Duration of contact",
        "Proximity of contact",
        "Actions",
      ],
      data: [
        {
          Name:
            exposure.firstName && exposure.lastName
              ? exposure.firstName + " " + exposure.lastName
              : `Unassigned tag (${exposure.exposedPersonSerial})`,
          "Date and time of exposure": moment(
            new Date(exposure.onsetTime)
          ).format("lll"),
          Role: exposure.status,
          "Duration of contact": exposure.durationInMinutes + " mins",
          "Proximity of contact": exposure.avgDistanceInCm + " cm",
          Actions: exposure.actions,
        },
      ],
    };
  }

  if (
    exposure &&
    infectedPersonPdfTable &&
    currentExposurePdfTable &&
    pdfHeaderImage
  ) {
    const body = (
      <View>
        <View style={pdfStyles.blockView}>
          <Text style={{ fontSize: "15px", margin: "10px" }}>Index case</Text>
          <View style={pdfStyles.rowView}>
            {infectedPersonPdfTable["column"].map((c) => (
              <Text
                style={
                  c === "Pathogen/disease" || c === "Date and time of exposure"
                    ? { width: "150px", paddingLeft: "5px" }
                    : c === "Name of case"
                    ? { width: "90px" }
                    : c === "Role"
                    ? { width: "40px", paddingLeft: "5px" }
                    : {
                        width: `${
                          100 / infectedPersonPdfTable["column"].length
                        }%`,
                        paddingLeft: "5px",
                      }
                }
              >
                {c}
              </Text>
            ))}
          </View>
          {infectedPersonPdfTable["data"].map((rowData) => (
            <View style={pdfStyles.rowView}>
              {infectedPersonPdfTable["column"].map((c) => (
                <Text
                  style={
                    c === "Pathogen/disease" ||
                    c === "Date and time of exposure"
                      ? { width: "150px", paddingLeft: "5px" }
                      : c === "Name of case"
                      ? {
                          width: "90px",
                        }
                      : c === "Role"
                      ? { width: "40px", paddingLeft: "5px" }
                      : {
                          width: `${
                            100 / infectedPersonPdfTable["column"].length
                          }%`,
                          paddingLeft: "5px",
                        }
                  }
                >
                  {rowData[c]}
                </Text>
              ))}
            </View>
          ))}
        </View>
        <View style={pdfStyles.blockView}>
          <Text style={{ fontSize: "15px", margin: "10px" }}>
            Exposed contact
          </Text>

          <View style={pdfStyles.rowView}>
            {currentExposurePdfTable["column"].map((c) => (
              <Text
                style={
                  c === "Pathogen/disease"
                    ? { width: "150px", paddingLeft: "5px" }
                    : c === "Name of case"
                    ? {
                        width: "90px",
                      }
                    : c === "Role"
                    ? { width: "60px", paddingLeft: "5px" }
                    : {
                        width: `${
                          100 / infectedPersonPdfTable["column"].length
                        }%`,
                        paddingLeft: "5px",
                      }
                }
              >
                {c}
              </Text>
            ))}
          </View>
          {currentExposurePdfTable["data"].map((rowData) => (
            <View style={pdfStyles.rowView}>
              {currentExposurePdfTable["column"].map((c) => (
                <Text
                  style={
                    c === "Pathogen/disease"
                      ? { width: "150px", paddingLeft: "5px" }
                      : c === "Name of case"
                      ? {
                          width: "90px",
                        }
                      : c === "Role"
                      ? { width: "60px", paddingLeft: "5px" }
                      : {
                          width: `${
                            100 / infectedPersonPdfTable["column"].length
                          }%`,
                          paddingLeft: "5px",
                        }
                  }
                >
                  {rowData[c]}
                </Text>
              ))}
            </View>
          ))}
        </View>
        <Text
          style={{
            position: "fixed",
            bottom: "10px",
            left: "505px",
            right: "10px",
            fontSize: "10px",
          }}
          render={({ pageNumber, totalPages }) => {
            return `Pg ${pageNumber} / ${totalPages}`;
          }}
          fixed
        />
      </View>
    );

    return ReportTemplate(
      pdfHeaderImage,
      reportGeneratedBy,
      reportGeneratedByEmail,
      body,
      `Infection Control Consequences Exposure`,
      reportId
    );
  } else return <Document></Document>;
};

export default MapReplayPdf;
