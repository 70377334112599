import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import EngineeringIcon from "@mui/icons-material/Engineering";
import "../style.css";
import {
  titleStyle,
  buttonTextStyle,
  buttonStyle,
  cyanBg,
} from "../../../styles/styles";
import GoBack from "../../../GoBack/GoBack";

const containerStyle = {
  width: "90%",
  margin: "130px auto 0",
};

export default function ReportUnderConstruction({ user }) {
  return (
    <div>
      <Box style={containerStyle}>
        <Grid
          container
          spacing={2}
          style={{ marginTop: "30px", padding: "0px 20px" }}
        >
          <Grid item xs={12} md={6}>
            <GoBack />

            <Typography variant="h5" component="div" sx={titleStyle}>
              Report Under Construction
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <div
              style={{
                width: "100%",
                display: "inline-flex",
                justifyContent: "flex-end",
                alignContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <button
                className="add-new-infection-button"
                style={{
                  ...buttonStyle,
                  ...cyanBg,
                  width: "auto",
                  justifyContent: "flex-end",
                  display: "inline-flex",
                  marginTop: "40px",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "top",
                    flexWrap: "wrap",
                  }}
                >
                  <a
                    href={`mailto:info@proxximos.com?subject=New report request from client #${user.clientId}`}
                    target="_blank"
                  >
                    <Typography sx={buttonTextStyle}>
                      Request New Report
                    </Typography>
                  </a>
                </div>
              </button>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={0} style={{ marginTop: "0px" }}>
          <Grid item xs={12}>
            <EngineeringIcon
              sx={{ fontSize: "200px", display: "block", margin: "auto" }}
            />
            <Typography
              variant="h6"
              component="div"
              sx={{
                margin: "auto",
                display: "block",
                width: "100%",
                textAlign: "center",
              }}
            >
              We apologize for the inconvenience. This report is not available
              yet.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
