import React from "react";

function StarEmptyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.727"
      height="23.103"
      viewBox="0 0 24.727 23.103"
    >
      <path
        id="Icon_ionic-ios-star"
        data-name="Icon ionic-ios-star"
        d="M24.114,10.68H16.652L14.384,3.913a.822.822,0,0,0-1.542,0L10.575,10.68H3.062a.814.814,0,0,0-.812.812.6.6,0,0,0,.015.137.78.78,0,0,0,.34.573l6.133,4.322L6.384,23.367a.814.814,0,0,0,.279.913.785.785,0,0,0,.457.2.994.994,0,0,0,.507-.183l5.986-4.266L19.6,24.3a.951.951,0,0,0,.507.183.729.729,0,0,0,.451-.2.8.8,0,0,0,.279-.913l-2.354-6.843,6.082-4.363.147-.127a.851.851,0,0,0,.264-.543A.859.859,0,0,0,24.114,10.68Z"
        transform="translate(-1.25 -2.375)"
        fill="#e0e0e0"
        stroke="#e0e0e0"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </svg>
  );
}

export default StarEmptyIcon;
