import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MapTab from "./TeamSafeMap/MapTab";
import Typography from "@mui/material/Typography";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ICSReportPdf from "../../PdfTemplates/InfectionControlSummaryPdf";
import moment from "moment";
import { Download as DownloadIcon } from "@mui/icons-material";
import {
  titleStyle,
  mainContainerNavStyle,
  buttonStyle,
  cyanBg,
  navyBg,
  buttonGroupContainerStyle,
  buttonJustifiedRightStyle,
  downloadContainerStyle,
  addIconStyle,
  zoomIconContainerStyle,
  zoomPositionStyle,
  containerStyle,
} from "../../styles/styles";
import AddIcon from "../../AddIcon/AddIcon";
import FormattedDate from "../../../components/FormattedDate/FormattedDate";
import { mapIconColors } from "../../helpers/helpers";

const buttonTextStyle = {
  marginBottom: 1.5,
  color: "white",
  textDecoration: "underline",
  fontWeight: 600,
  fontSize: 12,
  fontFamily: "Open Sans",
};

const buttonIconStyle = {
  fontSize: 20,
  marginLeft: "-9px",
  marginRight: "6px",
};

const downloadLinkText = {
  marginBottom: 0,
  color: "#283555",
  textDecoration: "underline",
  fontWeight: 600,
  display: "inline-block",
  fontSize: 12,
};

const downloadIconStyle = {
  color: "#283555",
  fontSize: 16,
  background: "transparent",
  margin: 0,
  padding: 0,
};

const downloadIconContainerStyle = {
  display: "inline-flex",
  background: "red",
  position: "relative",
  width: 25,
  height: 25,
  padding: 0,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#eaf0ff",
  marginTop: "10px",
};

export default function InfectionControlSummaryMap({ user, pdfHeaderImage }) {
  const navigate = useNavigate();
  const [data, setData] = React.useState();
  const [exposures, setExposures] = React.useState();
  const [pathogens, setPathogens] = React.useState();
  const [visibleDataObject, setVisibleDataObject] = React.useState();
  const [mapIconMetadata, setMapIconMetadata] = React.useState();
  const [serialColors, setSerialColors] = React.useState();
  const fetchMapDataInterval = React.useRef();

  const handleViewInList = () => {
    navigate("/");
  };

  const MemoizedPdf = useMemo(() => {
    return ICSReportPdf(
      user.firstName + " " + user.lastName,
      user.email,
      pdfHeaderImage,
      exposures,
      pathogens
    );
  }, [pdfHeaderImage, exposures, pathogens]);

  const fetchMapIconMetadata = (exposures) => {
    let newMapIconMetadata = {};

    if (!exposures) {
      return;
    }

    exposures.forEach((exposure, index) => {
      newMapIconMetadata[exposure.hardwareSerialNumber] = {
        name: `${exposure.firstName} ${exposure.lastName}`,
        color: mapIconColors[index],
        location: "",
        type: "person",
      };
    });

    setMapIconMetadata(newMapIconMetadata);
    return newMapIconMetadata;
  };

  const fetchMapData = async () => {
    if (!mapIconMetadata) {
      fetchMapIconMetadata(exposures);
    }

    if (mapIconMetadata) {
      const tagsString = `'${Object.keys(mapIconMetadata).join("','")}'`;
      let resp = await fetch(
        `${process.env.REACT_APP_RTLS_URL}/beacon_history/v2?&tag_serials=${tagsString}`
      );

      const jsonData = await resp.json();

      if (!jsonData) {
        return;
      }

      if (Object.keys(jsonData.results).length === 0) {
        return;
      }

      const formattedLocations = {};

      Object.keys(jsonData.results).forEach((key) => {
        const locationsArrayLength = jsonData.results[key].length - 1;
        const mostRecentLocation = jsonData.results[key][locationsArrayLength];
        formattedLocations[mostRecentLocation.serial_alphanumeric] =
          mostRecentLocation;
      });

      setData(formattedLocations);

      const mapMarkers = JSON.parse(JSON.stringify(formattedLocations || {}));

      setVisibleDataObject(mapMarkers);
    }
  };

  const fetchData = async () => {
    const pathogenData = await fetch(
      `${process.env.REACT_APP_RTLS_URL}/pathogens?clientId=${user.clientId}`
    );
    const pathogenDataJson = await pathogenData.json();

    const pathogenArray = pathogenDataJson.results;

    const formattedPathogenArray = pathogenArray.map((pathogen) => ({
      id: pathogen.pathogen_id,
      pathogenName: pathogen.pathogen_name,
      pathogenShortDesc: pathogen.pathogen_short_desc,
      pathogenLongDesc: pathogen.pathogen_long_desc,
      isAirborne: pathogen.is_airborne,
      isDroplet: pathogen.is_droplet,
      isIndirect: pathogen.is_indirect,
      isDirect: pathogen.is_direct,
      distanceBoundaryCm: pathogen.distance_boundary_cm,
      timeBoundaryMinutes: pathogen.time_boundary_minutes,
      indirectLingerTime: pathogen.indirect_linger_time,
      infectionSite: pathogen.infection_site,
    }));

    setPathogens(formattedPathogenArray);

    const infectionsData = await fetch(
      `${process.env.REACT_APP_RTLS_URL}/infections?clientId=${user.clientId}`
    );
    const infectionsDataJson = await infectionsData.json();
    const infectionsArray = infectionsDataJson.exposures;

    const filteredInfectionCases = infectionsArray.filter((row) => {
      const oneMonthAgo = moment(new Date()).subtract(1, "months").format();
      const oneMonthAgoTimestamp = new Date(oneMonthAgo).getTime();
      const onsetTimestamp = new Date(row.timestamp_of_onset).getTime();
      if (onsetTimestamp >= oneMonthAgoTimestamp) {
        return row;
      }
      return false;
    });

    const newExposures = filteredInfectionCases.map((exposure) => {
      const actionsString = JSON.parse(exposure.actions_for_case)
        .map((action) => action.value)
        .join(", ");
      return {
        id: exposure.infection_id,
        userId: exposure.user_id,
        firstName: exposure.first_name,
        lastName: exposure.last_name,
        status: exposure.status,
        pathogenId: exposure.pathogen_id,
        hardwareSerialNumber: exposure.hardware_serial_number,
        onsetTime: exposure.timestamp_of_onset,
        infectionId: exposure.infection_id,
        isConfirmed: exposure.is_confirmed,
        exposureType: exposure.infection_type,
        actions: exposure.actions_for_case,
        actionsString: actionsString,
        exposedContacts: exposure.number_of_exposed_contacts,
        contaminatedRooms: exposure.number_of_contaminated_rooms,
      };
    });

    setExposures(newExposures);

    const newMapIconMetadata = fetchMapIconMetadata(newExposures);

    const tagsString = `'${Object.keys(newMapIconMetadata).join("','")}'`;
    let rawData = await fetch(
      `${process.env.REACT_APP_RTLS_URL}/beacon_history/v2?&tag_serials=${tagsString}`
    );

    const jsonData = await rawData.json();

    if (!jsonData) {
      return;
    }

    if (Object.keys(jsonData.results).length === 0) {
      return;
    }

    const formattedLocations = {};

    Object.keys(jsonData.results).forEach((key) => {
      const locationsArrayLength = jsonData.results[key].length - 1;
      const mostRecentLocation = jsonData.results[key][locationsArrayLength];
      formattedLocations[mostRecentLocation.serial_alphanumeric] =
        mostRecentLocation;
    });

    setData(formattedLocations);

    const mapMarkers = JSON.parse(JSON.stringify(formattedLocations || {}));

    setVisibleDataObject(mapMarkers);

    clearTimeout(fetchMapDataInterval.current);
    fetchMapDataInterval.current = setTimeout(fetchData, 5000);
  };

  const handleAddNewCase = () => {
    navigate("/infection-safe/manage-case");
  };

  React.useEffect(() => {
    if (!data) {
      fetchData();
    }
  }, []);

  return (
    <div>
      <Box style={containerStyle}>
        <FormattedDate />

        <div style={mainContainerNavStyle}>
          <Grid
            container
            spacing={2}
            style={{ justifyContent: "space-between" }}
          >
            <Grid item xs={12} sm={12} lg={12} display={{ lg: "inline-flex" }}>
              <Grid
                container
                spacing={2}
                style={{
                  display: "inline-flex",
                  justifyContent: "space-between",
                }}
              >
                <Grid item xs={12} lg={12}>
                  <div style={{ display: "inline-flex", width: "100%" }}>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{
                        ...titleStyle,
                        whiteSpace: "nowrap",
                        marginRight: "21px",
                      }}
                    >
                      Infection Summary Control - Map
                    </Typography>
                    <div
                      className="button-group-container"
                      style={buttonGroupContainerStyle}
                    >
                      <Grid
                        container
                        spacing={2}
                        style={{
                          display: "inline-flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item xs={12} sm={12} md={"auto"}>
                          <div
                            style={{
                              display: "inline-flex",
                              justifyContent: "flex-end",
                              width: "100%",
                            }}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={12} md={"auto"}>
                                <div style={buttonJustifiedRightStyle}>
                                  <button
                                    className="view-map-button"
                                    style={{
                                      ...buttonStyle,
                                      ...navyBg,
                                      width: "100%",
                                      justifyContent: "center",
                                    }}
                                    onClick={handleViewInList}
                                  >
                                    <Typography sx={buttonTextStyle}>
                                      View in List
                                    </Typography>
                                  </button>
                                </div>
                              </Grid>
                              <Grid item xs={12} md={"auto"}>
                                <div style={buttonJustifiedRightStyle}>
                                  <button
                                    className="add-new-infection-button"
                                    style={{
                                      ...buttonStyle,
                                      ...cyanBg,
                                      width: "100%",
                                      justifyContent: "center",
                                      display: "inline-flex",
                                    }}
                                    onClick={handleAddNewCase}
                                  >
                                    <div
                                      style={{
                                        display: "inline-flex",
                                        alignItems: "top",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      <AddIcon
                                        style={{
                                          ...buttonTextStyle,
                                          ...buttonIconStyle,
                                          ...addIconStyle,
                                        }}
                                      />
                                      <Typography sx={buttonTextStyle}>
                                        Add new infection case
                                      </Typography>
                                    </div>
                                  </button>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <Grid item xs={12} sm={12} md={"auto"}>
                      <div
                        style={{
                          display: "inline-flex",
                          justifyContent: "flex-end",
                          width: "100%",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={"auto"}>
                            <PDFDownloadLink
                              document={MemoizedPdf}
                              fileName={`Proxximos-ICS-${moment(
                                new Date()
                              ).format("LLL")}.pdf`}
                            >
                              {({ blob, url, loading, error }) =>
                                loading ? (
                                  "Loading document..."
                                ) : (
                                  <div style={downloadContainerStyle}>
                                    <Typography sx={downloadLinkText}>
                                      Download Report
                                    </Typography>
                                    <div
                                      className="icon-container"
                                      style={downloadIconContainerStyle}
                                    >
                                      <DownloadIcon
                                        className="download-icon"
                                        style={downloadIconStyle}
                                      />
                                    </div>
                                  </div>
                                )
                              }
                            </PDFDownloadLink>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <br></br>

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div
              style={{
                height: "80%",
                width: "100%",
              }}
            >
              <MapTab
                data={visibleDataObject}
                mapIconMetadata={mapIconMetadata}
                user={user}
                iconBackgroundColor={"#1763F7"}
                controlsPosition="tr"
                zoomIconContainerStyle={zoomIconContainerStyle}
                zoomPositionStyle={zoomPositionStyle}
              ></MapTab>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
