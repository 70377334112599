import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "../HomeTabs/style.css";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  useGridApiRef,
} from "@mui/x-data-grid";
import { titleStyle, containerStyle } from "../../styles/styles";
import GoBack from "../../../components/GoBack/GoBack";

export default function ApplicationSettings({ user }) {
  const { state } = useLocation();
  const apiRef = useGridApiRef();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [checked, setChecked] = React.useState({});
  const [pathogens, setPathogens] = React.useState();
  const [pathogenNameError, setPathogenNameError] = React.useState(false);
  const [needsRefresh, setNeedsRefresh] = React.useState(false);
  const [snackbarSuccess, setSnackbarSuccess] = React.useState(false);
  const [infectionSiteError, setInfectionSiteError] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(
    state && snackbarSuccess ? true : false
  );
  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const RenderCheckBox = (params) => {
    const [checked, setChecked] = React.useState(
      parseInt(params.value) === 1 ? true : false
    ); // Initiated react binded value with param from `rows`

    // Handler for user clicks to set checkbox mark or unset it
    const handleChange = (event) => {
      setChecked(event.target.checked);

      apiRef.current.setEditCellValue({
        id: params.id,
        field: params.field,
        value: event.target.checked ? 1 : 0,
      });
    };
    //The bind for dynamic mark/unmark: checked={checked}
    //The handler for user clicks: onChange={handleChange}
    return (
      <Checkbox
        size="lg"
        disabled={params.readonly ? true : false}
        checked={checked}
        onChange={handleChange}
      />
    );
  };

  const handleEditClick = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (newPathogen) => {
    if (
      !(
        newPathogen.infectionSite === "Gastrointestinal" ||
        newPathogen.infectionSite === "Skin" ||
        newPathogen.infectionSite === "Respiratory"
      )
    ) {
      setInfectionSiteError(true);
      return;
    } else {
      setInfectionSiteError(false);
    }

    if (!newPathogen.pathogenName.length > 0) {
      setPathogenNameError(true);
      return;
    } else {
      setPathogenNameError(false);
    }

    setRowModesModel({
      ...rowModesModel,
      [newPathogen.id]: { mode: GridRowModes.View },
    });
  };

  const handleCancelClick = (id) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = pathogens.find((row) => row.id === id);
    if (editedRow.isNew) {
      setPathogens(pathogens.filter((row) => row.id !== id));
    }
  };

  const PATHOGEN_COLS = [
    {
      field: "id",
    },
    {
      field: "pathogenName",
      headerName: "Name",
      flex: 1,
      align: "left",
      headerAlign: "left",
      editable: true,
      preProcessEditCellProps: (params) => {
        const hasError = !(params.props.value.length > 0);
        if (hasError) {
          setPathogenNameError(true);
        } else {
          setPathogenNameError(false);
        }
        return { ...params.props, error: hasError };
      },
      renderCell: (params) => {
        return (
          <>
            <div style={{ display: "block" }}>
              <div>{params.value}</div>
              <div style={{ color: "#666", fontSize: 11 }}>
                {params.row.pathogenShortDesc}
              </div>
            </div>
          </>
        );
      },
      renderEditCell: (params) => {
        return (
          <>
            <TextField
              error={pathogenNameError}
              InputProps={{
                style: {
                  color: "black",
                  fontWeight: "400",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  margin: "10px",
                  height: "44px",
                },
              }}
              id={String(params.id)}
              value={params.value}
              onChange={(evt) => {
                apiRef.current.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: evt.target.value,
                });
                let newRow = pathogens.filter((row) => row.id === params.id)[0];
                newRow.pathogenName = evt.target.value;
              }}
            />
            {pathogenNameError ? (
              <FormHelperText error={true}>
                Pathogen name invalid.
              </FormHelperText>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    { field: "pathogenShortDesc" },
    { field: "pathogenLongDesc" },
    {
      field: "isAirborne",
      headerName: "Airborne",
      width: 80,
      editable: true,
      renderCell: (params) => {
        return (
          <>
            <RenderCheckBox {...params} readonly />
          </>
        );
      },
      renderEditCell: (params) => {
        return <RenderCheckBox {...params} />;
      },
      align: "left",
      headerAlign: "left",
    },
    {
      field: "isDroplet",
      headerName: "Droplet",
      width: 80,
      editable: true,
      renderCell: (params) => {
        return (
          <>
            <RenderCheckBox {...params} readonly />
          </>
        );
      },
      renderEditCell: (params) => {
        return <RenderCheckBox {...params} />;
      },
      align: "left",
      headerAlign: "left",
    },
    {
      field: "isDirect",
      headerName: "Direct",
      width: 80,
      editable: true,
      renderCell: (params) => {
        return (
          <>
            <RenderCheckBox {...params} readonly />
          </>
        );
      },
      renderEditCell: (params) => {
        return <RenderCheckBox {...params} />;
      },
      align: "left",
      headerAlign: "left",
    },
    {
      field: "isIndirect",
      headerName: "Indirect",
      width: 80,
      editable: true,
      renderCell: (params) => {
        return (
          <>
            <RenderCheckBox {...params} readonly />
          </>
        );
      },
      renderEditCell: (params) => {
        return <RenderCheckBox {...params} />;
      },
      align: "left",
      headerAlign: "left",
    },
    {
      field: "distanceBoundaryCm",
      headerName: "Distance boundary (cm)",
      type: "number",
      editable: true,
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "timeBoundaryMinutes",
      headerName: "Time boundary (mins)",
      type: "number",
      editable: true,
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "indirectLingerTime",
      headerName: "Indirect linger time (mins)",
      type: "number",
      editable: true,
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "infectionSite",
      headerName: "Infection site",
      editable: true,
      flex: 1,
      align: "left",
      headerAlign: "left",
      preProcessEditCellProps: (params) => {
        const hasError = !(
          params.props.value === "Gastrointestinal" ||
          params.props.value === "Skin" ||
          params.props.value === "Respiratory"
        );
        if (hasError) {
          setInfectionSiteError(true);
        } else {
          setInfectionSiteError(false);
        }
        return { ...params.props, error: hasError };
      },
      renderEditCell: (params) => {
        const hasError = !(
          params.value === "Gastrointestinal" ||
          params.value === "Skin" ||
          params.value === "Respiratory"
        );
        if (hasError) {
          setInfectionSiteError(true);
        } else {
          setInfectionSiteError(false);
        }

        return (
          <>
            <div style={{ display: "block" }}>
              <FormControl
                size="small"
                fullWidth
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  width: "100%",
                }}
                error={hasError ? true : false}
              >
                <Select
                  id={String(params.id)}
                  value={params.value}
                  onChange={(evt) => {
                    apiRef.current.setEditCellValue({
                      id: params.id,
                      field: params.field,
                      value: evt.target.value,
                    });
                    let newRow = pathogens.filter(
                      (row) => row.id === params.id
                    )[0];
                    newRow.infectionSite = evt.target.value;
                  }}
                >
                  <MenuItem value=""></MenuItem>
                  <MenuItem value="Gastrointestinal">Gastrointestinal</MenuItem>
                  <MenuItem value="Skin">Skin</MenuItem>
                  <MenuItem value="Respiratory">Respiratory</MenuItem>
                </Select>
              </FormControl>
              {hasError ? (
                <FormHelperText error={true}>
                  Selection cannot be blank.
                </FormHelperText>
              ) : (
                <></>
              )}
            </div>
          </>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      cellClassName: "actions",
      align: "left",
      headerAlign: "left",
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={() => handleSaveClick(row)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={() => handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const [roomTypes, setRoomTypes] = React.useState([
    "All",
    "Kitchens",
    "Toilets",
    "Dining Rooms",
  ]);

  const handleRoomTypeCheckboxChange = (evt, index) => {
    const newChecked = { ...checked };

    newChecked[index] = evt.target.checked;

    if (index === "All" && evt.target.checked) {
      roomTypes.forEach((type) => {
        if (type !== "All") {
          newChecked[type] = evt.target.checked;
        }
      });
    }
    if (index !== "All" && !evt.target.checked && newChecked["All"] === true) {
      newChecked["All"] = false;
    }

    setChecked(newChecked);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const fetchPathogens = async () => {
    const pathogenData = await fetch(
      `${process.env.REACT_APP_RTLS_URL}/pathogens?clientId=${user.clientId}`
    );
    const pathogenDataJson = await pathogenData.json();

    const pathogenArray = pathogenDataJson.results;

    setPathogens(
      pathogenArray.map((pathogen) => ({
        id: pathogen.pathogen_id,
        pathogenName: pathogen.pathogen_name,
        pathogenShortDesc: pathogen.pathogen_short_desc,
        pathogenLongDesc: pathogen.pathogen_long_desc,
        isAirborne: pathogen.is_airborne,
        isDroplet: pathogen.is_droplet,
        isIndirect: pathogen.is_indirect,
        isDirect: pathogen.is_direct,
        distanceBoundaryCm: pathogen.distance_boundary_cm,
        timeBoundaryMinutes: pathogen.time_boundary_minutes,
        indirectLingerTime: pathogen.indirect_linger_time,
        infectionSite: pathogen.infection_site,
      }))
    );

    setNeedsRefresh(false);
  };

  const handleSave = async (newPathogen) => {
    try {
      const que = {
        ...newPathogen,
        pathogenId: newPathogen.id,
        clientId: user.clientId,
      };

      const response = await fetch(
        `${process.env.REACT_APP_RTLS_URL}/pathogens`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(que),
        }
      );
      const data = await response.json();

      if (data.error) {
        setSnackbarSuccess(false);
        setErrorMessage(data.error.code);
        setSnackbarOpen(true);
      } else {
        setSnackbarSuccess(true);
        setSnackbarOpen(true);
        setNeedsRefresh(true);
      }
    } catch (error) {
      console.log("Error:", error);
      setSnackbarSuccess(false);
      setErrorMessage(error);
      setSnackbarOpen(true);
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    const newPathogens = pathogens.map((row) => {
      if (row.id === newRow.id) {
        return updatedRow;
      } else {
        return row;
      }
    });
    setPathogens(newPathogens);
    handleSave(newRow);

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  React.useEffect(() => {
    if (!pathogens || needsRefresh) {
      fetchPathogens();
    }
  });

  return (
    <div>
      <Box style={containerStyle}>
        <GoBack />

        <br></br>
        <Typography variant="h5" component="div" sx={titleStyle}>
          Application Settings
        </Typography>

        <Grid
          container
          spacing={2}
          style={{
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Grid item xs={12}>
            {pathogens ? (
              <div style={{ height: 400, width: "100%" }}>
                <DataGrid
                  apiRef={apiRef}
                  sx={{ backgroundColor: "#fff" }}
                  columnVisibilityModel={{
                    id: false,
                    pathogenShortDesc: false,
                    pathogenLongDesc: false,
                  }}
                  rowHeight={75}
                  experimentalFeatures={{ ariaV7: true, newEditingApi: true }}
                  columns={PATHOGEN_COLS}
                  rows={pathogens}
                  editMode="row"
                  rowModesModel={rowModesModel}
                  onRowModesModelChange={handleRowModesModelChange}
                  onRowEditStop={handleRowEditStop}
                  processRowUpdate={(props) => {
                    return processRowUpdate({ ...props });
                  }}
                />
              </div>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        {snackbarSuccess ? (
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%", "& .MuiSvgIcon-root": { color: "white" } }}
          >
            Saved successfully
          </Alert>
        ) : (
          <Alert
            onClose={handleSnackbarClose}
            severity="error"
            sx={{ width: "100%", "& .MuiSvgIcon-root": { color: "white" } }}
          >
            Error: {errorMessage}
          </Alert>
        )}
      </Snackbar>
    </div>
  );
}
