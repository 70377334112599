import { StyleSheet } from "@react-pdf/renderer";

const formatDate = (date) => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dayOfWeek = daysOfWeek[date.getDay()];
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${dayOfWeek}, ${month} ${day} ${year}`;
};

const getAccessToken = async (getAccessTokenSilently) => {
  try {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
        scope: "openid profile email",
      },
    });

    return accessToken;
  } catch (e) {
    console.log(e.message);
  }
};

export const convertMinutes = (value) => {
  if (value < 60) {
    let unit = value === 1 ? "minute" : "minutes";
    return `${Math.round(value)} ${unit}`;
  } else if (value < 1440) {
    // Less than 24 hours
    let hours = Math.round(value / 60);
    let unit = hours === 1 ? "hour" : "hours";
    return `${hours} ${unit}`;
  } else {
    // 24 hours or more
    let days = Math.round(value / 1440);
    let unit = days === 1 ? "day" : "days";
    return `${days} ${unit}`;
  }
};

export const pdfStyles = StyleSheet.create({
  rowView: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #EEE",
    paddingTop: 8,
    paddingBottom: 8,
    textAlign: "left",
    fontSize: 10,
    height: "53px",
    marginLeft: 10,
    marginRight: 10,
  },
});

const mapIconColors = [
  "#55fdfd",
  "#afe190",
  "#68bad8",
  "#26a1db",
  "#1b299e",
  "#0434c8",
  "#acb9fc",
  "#030677",
  "#ccd159",
  "#efd761",
  "#cfe0a5",
  "#e9c6ca",
  "#204aeb",
  "#260607",
  "#7279c2",
  "#3f317c",
  "#8f1a2a",
  "#5cdef3",
  "#e85449",
  "#7fb859",
  "#d67e8f",
  "#543c4d",
  "#b0765d",
  "#17351d",
  "#9c5b43",
  "#c6e0db",
  "#1fc29a",
  "#1b7437",
  "#ab83ce",
  "#f92f85",
  "#d79c3e",
  "#c336ed",
  "#4a9174",
  "#44ba75",
  "#009084",
  "#8d078d",
  "#01ccfb",
  "#95837d",
  "#a6540f",
  "#a66dc0",
  "#10001e",
  "#82caef",
  "#0cdd81",
  "#3a92c3",
  "#b9668c",
  "#480897",
  "#2628f7",
  "#45614c",
  "#48843e",
  "#1e36a4",
];

const RISK_LEVELS = ["Low", "Medium", "High", "Open"];

export { formatDate, mapIconColors, getAccessToken, RISK_LEVELS };
